import {Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material'
import React from 'react'

import type {Stats} from '../../screens/Finance'
import {AdminWrap} from '../admin/AdminWrap'

type AdminStatisticsProps = {
  statistics: Stats
}

export const AdminStatistics = ({statistics}: AdminStatisticsProps) => {
  const rows = Object.entries(statistics)
  return (
    <AdminWrap>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Approver Email</TableCell>
              <TableCell>Completed</TableCell>
              <TableCell>In Progress</TableCell>
              <TableCell>Rejected</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(([email, value]) => (
              <TableRow key={email}>
                <TableCell>{email}</TableCell>
                <TableCell>{value.COMPLETED}</TableCell>
                <TableCell>{value.IN_PROGRESS}</TableCell>
                <TableCell>{value.REJECTED}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {rows.length === 0 && (
          <Stack sx={{py: 1}} direction="row" justifyContent="center">
            <Typography color="gray" variant="caption" component="div">
              No approvals.
            </Typography>
          </Stack>
        )}
      </TableContainer>
    </AdminWrap>
  )
}
