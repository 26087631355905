import {Button, TextField} from '@mui/material'
import React, {useState} from 'react'

import {minEmailLength} from '../../constants'
import {adminCreateEmptyProfile} from '../../helpers/firebaseHelpers'
import {useLoading} from '../../helpers/loading'
import {AdminWrap} from './AdminWrap'

type CreateEmptyProfileProps = {
  taxYear: number
}

export const CreateEmptyProfile = ({taxYear}: CreateEmptyProfileProps) => {
  const {setLoading} = useLoading()
  const [email, setEmail] = useState('')

  return (
    <AdminWrap
      title={`Create empty ${taxYear} profile`}
      actions={
        <>
          <Button
            data-testid="create-empty-profile-reset-button"
            variant="text"
            onClick={(): void => {
              setEmail('')
            }}
          >
            Clear
          </Button>
          <Button
            data-testid="create-empty-profile-save-button"
            variant="contained"
            disabled={email.length < minEmailLength}
            onClick={(): void => {
              setLoading(true)
              adminCreateEmptyProfile(taxYear, email)
                .then((res) => {
                  alert(res)
                  setEmail('')
                })
                .catch((e) => {
                  alert(e)
                })
                .finally(() => setLoading(false))
            }}
          >
            Create
          </Button>
        </>
      }
    >
      <TextField
        data-testid="create-empty-profile-email"
        required
        fullWidth
        id="outlined-required"
        label="Email"
        type="email"
        value={email}
        size="small"
        onChange={(e): void => setEmail(e.target.value)}
      />
    </AdminWrap>
  )
}
