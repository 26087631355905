import {Button, TextField} from '@mui/material'
import React, {useState} from 'react'

import {minEmailLength} from '../../constants'
import {adminAddUser} from '../../helpers/firebaseHelpers'
import {useLoading} from '../../helpers/loading'
import {AdminWrap} from './AdminWrap'

type AddUserRoleProps = {
  role: 'finance' | 'admin'
}

export const AddUserRole = ({role}: AddUserRoleProps) => {
  const {setLoading} = useLoading()
  const [email, setEmail] = useState('')

  return (
    <AdminWrap
      title={`Add ${role} role to user`}
      actions={
        <>
          <Button
            data-testid={`new-${role}-user-reset-button`}
            variant="text"
            onClick={(): void => {
              setEmail('')
            }}
          >
            Clear
          </Button>
          <Button
            data-testid={`new-${role}-user-save-button`}
            variant="contained"
            disabled={email.length < minEmailLength}
            onClick={(): void => {
              setLoading(true)
              adminAddUser(role, email)
                .then((res) => {
                  alert(res)
                  setEmail('')
                })
                .catch((e) => {
                  alert(e)
                })
                .finally(() => setLoading(false))
            }}
          >
            Add
          </Button>
        </>
      }
    >
      <TextField
        required
        label="Email"
        type="email"
        value={email}
        size="small"
        onChange={(e): void => setEmail(e.target.value)}
      />
    </AdminWrap>
  )
}
