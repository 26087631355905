import {Box, TextField, Typography} from '@mui/material'
import React from 'react'

import {uploadToStorage} from '../../helpers/firebaseHelpers'
import type {Data, DataItem} from '../../types'
import {InfoIconComponent} from '../common/InfoIcon'
import {SingleFileUpload} from '../common/SingleFileUpload'

const otherIncomeConfig = {
  zPodnikaniaVykonanehoPodlaOsobitnychPredpisov: 'Z podnikania vykonávaného podľa osobitných predpisov',
  zPrenajmuNehnutelnosti: 'Z prenájmu nehnuteľností',
  zPrevoduCennychPapierov: 'Z prevodu cenných papierov',
  zDividendov: 'Z podielu na zisku (dividendy)',
  zPredajaVirtualnejMeny: 'Z predaja virtuálnej meny',
} as const

export type OtherIncomePrefix = keyof typeof otherIncomeConfig

export const otherIncomeTypes = Object.keys(otherIncomeConfig) as OtherIncomePrefix[]

type InePrijmyFieldProps = {
  label: string
  dataKeyPrefix: OtherIncomePrefix
  revenue: number
  expenditure: number
  handleDataUpdate: (dataItem: DataItem) => void
  disabled?: boolean
}

const InePrijmyField = ({
  label,
  dataKeyPrefix,
  revenue,
  expenditure,
  handleDataUpdate,
  disabled,
}: InePrijmyFieldProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
      }}
    >
      <Typography sx={{width: '40%'}} variant="body1">
        {label}
      </Typography>
      <Box>
        <TextField
          label="Príjmy"
          value={revenue}
          margin="normal"
          type="number"
          onWheel={(e): void => (e.target as HTMLDivElement).blur()}
          disabled={disabled || false}
          onChange={(event): void => {
            handleDataUpdate({
              field: `${dataKeyPrefix}Prijmy`,
              value: event.target.value,
            })
          }}
        />
        <TextField
          label="Výdavky"
          value={expenditure}
          margin="normal"
          type="number"
          onWheel={(e): void => (e.target as HTMLDivElement).blur()}
          disabled={disabled || false}
          onChange={(event): void => {
            handleDataUpdate({
              field: `${dataKeyPrefix}Vydavky`,
              value: event.target.value,
            })
          }}
        />
      </Box>
    </Box>
  )
}

type InePrijmyProps = {
  data: Data
  handleDataUpdate: (dataItem: DataItem) => void
}

export const InePrijmy = ({data, handleDataUpdate}: InePrijmyProps) => {
  const {viewOnly} = data

  const getRevenue = (prefix: OtherIncomePrefix): number => {
    try {
      return Number(data[`${prefix}Prijmy`] ?? 0)
    } catch {
      return 0
    }
  }

  const getExpenditure = (prefix: OtherIncomePrefix): number => {
    try {
      return Number(data[`${prefix}Vydavky`] ?? 0)
    } catch {
      return 0
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': {width: '25ch', marginLeft: '1ch'},
        '& .MuiBox-root': {
          display: 'flex',
          flexWrap: 'wrap',
        },
        m: 2,
      }}
    >
      {otherIncomeTypes.map((dataKeyPrefix) => (
        <InePrijmyField
          key={dataKeyPrefix}
          label={otherIncomeConfig[dataKeyPrefix]}
          dataKeyPrefix={dataKeyPrefix}
          handleDataUpdate={handleDataUpdate}
          revenue={getRevenue(dataKeyPrefix)}
          expenditure={getExpenditure(dataKeyPrefix)}
          disabled={viewOnly}
        />
      ))}

      <Typography key={'attachments-typography'} variant="h5" component="div" sx={{my: 2}}>
        Priložte dokumenty relevantné k iným príjmom
      </Typography>
      <InfoIconComponent
        key={'attachments-infoIconComponent'}
        infoText={'Priložte dokumenty relevantné k iným príjmom (pri viacero dokumentoch zazipovat)'}
      >
        <SingleFileUpload
          label="Dokumenty k iným príjmom"
          key={'attachments-singleFileUpload'}
          existingAttachment={data.inePrijmyDokumenty ?? null}
          disabled={viewOnly}
          fileChangeFunc={async (file) => {
            await uploadToStorage(file, `inePrijmyDokumenty`, data.id).then((downloadURL) => {
              handleDataUpdate({
                field: 'inePrijmyDokumenty',
                value: downloadURL,
                forceSave: true,
              })
            })
          }}
        />
      </InfoIconComponent>
    </Box>
  )
}
