import {Stack, Typography} from '@mui/material'
import React from 'react'

export const EmptyTableMessage = () => {
  return (
    <Stack sx={{py: 1}} direction="row" justifyContent="center">
      <Typography color="gray" variant="caption" component="div">
        Nemáte žiaden záznam.
      </Typography>
    </Stack>
  )
}
