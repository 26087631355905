import React, {type ReactNode, useState} from 'react'

import {LoadingContextProvider} from '../../helpers/loading'

type LoadingWrapperProps = {
  children?: ReactNode
}

export const LoadingWrapper = ({children}: LoadingWrapperProps) => {
  const [loading, setLoading] = useState(false)
  return <LoadingContextProvider value={{loading, setLoading}}>{children}</LoadingContextProvider>
}
