import type {TextFieldProps} from '@mui/material'
import {Paper, Table as MuiTable, TableBody, TableCell, TableContainer, TableRow} from '@mui/material'
import React, {type FC, type ReactNode, useCallback, useEffect, useMemo, useState} from 'react'

import {EmptyTableMessage} from './EmptyTableMessage'
import {Modal} from './Modal'
import {TableDateCell} from './TableDateCell'
import {TableFooter} from './TableFooter'
import {TableHead} from './TableHead'
import {TableHeader} from './TableHeader'
import {TableNumberCell} from './TableNumberCell'

export type RowType = Record<string, unknown>

export type ColumnType<Row extends RowType> = {
  label: string
  property: Extract<keyof Row, string>
  inputType: 'text' | 'number' | 'date' | 'custom'
  getDefault?: () => unknown
  isValid?: (row: Row) => boolean
  extraProps?: Partial<TextFieldProps>
  customCellRenderer?: (row: Row) => ReactNode
  CustomModalEditor?: FC<{
    value: Row
    dataId: string
    onChange: (value: Partial<Row>) => void
  }>
}

type TableProps<Row extends RowType> = {
  dataId: string
  rows: Row[]
  columns: Array<ColumnType<Row>>
  title: string
  subtitle?: string
  handleDataUpdate: (rows: Row[]) => void
  disabled?: boolean
  hideFooter?: boolean
  sortBy?: Extract<keyof Row, string>
}

const removeSelectedProp = <Row extends RowType>(row: Row & {selected: boolean}): Row => {
  const {selected, ...rest} = row
  // to get rid of this cast we'd have to ensure that the original Row
  // never includes 'selected' key (in real data it does not)
  return rest as unknown as Row
}

export const Table = <Row extends RowType>({
  dataId,
  rows,
  columns,
  title,
  subtitle,
  handleDataUpdate,
  disabled,
  hideFooter,
  sortBy,
}: TableProps<Row>) => {
  const sortRows = useCallback(
    <T extends Row>(rowsToSort: T[]) =>
      sortBy == null
        ? rowsToSort
        : rowsToSort.sort((r1, r2) => {
            const value1 = ((r1[sortBy] as string | undefined) ?? '').toString()
            const value2 = ((r2[sortBy] as string | undefined) ?? '').toString()
            return value1.localeCompare(value2)
          }),
    [sortBy],
  )

  const [modalOpened, setModalOpened] = useState(false)
  const [internalRows, setInternalRows] = useState<Array<Row & {selected: boolean}>>([])

  useEffect(() => {
    setInternalRows(
      sortRows(
        rows.map((row) => ({
          ...row,
          selected: false,
        })),
      ),
    )
  }, [rows, sortRows])

  const selectedRows = useMemo(() => internalRows.filter((row) => row.selected), [internalRows])
  const selectedRow = selectedRows.map(removeSelectedProp)[0]
  const [currentlyEditedRow, setCurrentlyEditedRow] = useState<Row | null>(null)

  const defaultData = columns.reduce(
    (emptyObj, {property, inputType, getDefault}) => ({
      ...emptyObj,
      [property]: getDefault ? getDefault() : inputType === 'number' ? 0 : '',
    }),
    {} as Row,
  )

  const [initialData, setInitialData] = useState<Row>(defaultData)

  const handleClick = (rowIndex: number) => {
    setInternalRows(
      internalRows.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              selected: !row.selected,
            }
          : row,
      ),
    )
  }

  const handleDelete = (): void => {
    const notSelectedRows = internalRows.filter((row) => !row.selected)
    const newRows = notSelectedRows.map(removeSelectedProp)
    handleDataUpdate(newRows)
    setInternalRows(notSelectedRows)
  }

  const handleAdd = () => {
    setInitialData(defaultData)
    setCurrentlyEditedRow(null)
    setModalOpened(true)
  }

  const handleEdit = () => {
    setInitialData(selectedRow !== undefined ? selectedRow : defaultData)
    setCurrentlyEditedRow(selectedRow ?? null)
    handleDelete()
    setModalOpened(true)
  }

  const handleDuplicate = () => {
    setInitialData(selectedRow !== undefined ? selectedRow : defaultData)
    setCurrentlyEditedRow(null)
    setModalOpened(true)
  }

  const handleModalClose = (): void => {
    setModalOpened(false)
    if (currentlyEditedRow !== null) {
      handleDataUpdate(sortRows([...rows, currentlyEditedRow]))
      setInternalRows(
        sortRows([
          ...internalRows,
          {
            ...currentlyEditedRow,
            selected: false,
          },
        ]),
      )
    }
  }

  const handleModalConfirm = (row: Row): void => {
    setCurrentlyEditedRow(null)
    handleDataUpdate(sortRows([...rows, row]))
    setInternalRows(sortRows([...internalRows, {...row, selected: false}]))
    setModalOpened(false)
  }

  return (
    <>
      <Paper sx={{width: '100%', mb: 5}} variant="outlined">
        <TableContainer>
          <TableHeader
            handlers={{handleAdd, handleEdit, handleDelete, handleDuplicate}}
            title={title}
            subtitle={subtitle ?? ''}
            selectedRowsCount={selectedRows.length}
            disabled={disabled ?? false}
          />
          <MuiTable size="small">
            <TableHead columns={columns} />
            <TableBody>
              {internalRows.map((row, rowIndex) => (
                <TableRow
                  sx={{
                    position: 'relative',
                    '&.Mui-selected': {
                      // todo move to theme - https://mui.com/material-ui/api/table-row/#css,
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        borderLeft: '3px solid',
                        borderColor: 'primary.main',
                      },
                    },
                  }}
                  key={rowIndex}
                  hover
                  onClick={disabled ? undefined : () => handleClick(rowIndex)}
                  selected={row.selected}
                >
                  {columns.map((column) => {
                    if (column.inputType === 'custom') {
                      return <TableCell key={column.property}>{column.customCellRenderer?.(row)}</TableCell>
                    }
                    const value = row[column.property] as number | string | undefined

                    if (column.inputType === 'date') {
                      return <TableDateCell key={column.property} date={value?.toString() ?? ''} />
                    }

                    if (column.inputType === 'number') {
                      return <TableNumberCell key={column.property} amount={value?.toString() ?? ''} />
                    }

                    return <TableCell key={column.property}>{value ?? ''}</TableCell>
                  })}
                </TableRow>
              ))}
            </TableBody>
            {internalRows.length !== 0 && !hideFooter && <TableFooter rows={rows} columns={columns} />}
          </MuiTable>
          {internalRows.length === 0 && <EmptyTableMessage />}
        </TableContainer>
      </Paper>
      <Modal
        open={modalOpened}
        columns={columns}
        initialData={initialData}
        defaultData={defaultData}
        handleModalClose={handleModalClose}
        handleModalConfirm={handleModalConfirm}
        dataId={dataId}
      />
    </>
  )
}
