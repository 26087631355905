import {FilePresentRounded, UploadFileRounded} from '@mui/icons-material'
import {Button, Stack, Typography} from '@mui/material'
import React from 'react'

import {useLoading} from '../../helpers/loading'

type SingleFileUploadProps = {
  fileChangeFunc: (arg0: Blob | Uint8Array | ArrayBuffer) => Promise<void>
  existingAttachment: string | null
  label: string
  disabled?: boolean
  // use if there are more uploads in one page
  fileId?: string
}

export const SingleFileUpload = ({
  fileChangeFunc,
  existingAttachment,
  label,
  disabled = false,
  fileId = '',
}: SingleFileUploadProps) => {
  const {setLoading} = useLoading()

  return (
    <Stack data-testid="wrapper" direction="row" alignItems="center" spacing={2}>
      {existingAttachment !== null && existingAttachment !== '' ? (
        <Button
          variant="text"
          component="a"
          href={existingAttachment}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<FilePresentRounded />}
        >
          {label}
        </Button>
      ) : (
        <Typography color="GrayText">No file selected</Typography>
      )}
      <label htmlFor={`file-upload${fileId}`}>
        <input
          data-testid={`upload${fileId}`}
          type="file"
          id={`file-upload${fileId}`}
          style={{display: 'none'}}
          disabled={disabled}
          onChange={async ({target: {files}}): Promise<void> => {
            if (files !== null && files.length > 0 && files[0] !== undefined) {
              setLoading(true)
              await fileChangeFunc(files[0]).finally(() => setLoading(false))
            } else {
              throw new Error('File is undefined')
            }
          }}
        />
        <Button variant="contained" disabled={disabled ?? false} component="span" startIcon={<UploadFileRounded />}>
          Upload
        </Button>
      </label>
    </Stack>
  )
}
