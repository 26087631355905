import {TableCell} from '@mui/material'
import React from 'react'

import {formatDate} from '../../../utils'

type TableDateCellProps = {
  date: string
}

export const TableDateCell = ({date}: TableDateCellProps) => {
  return <TableCell>{formatDate(date)}</TableCell>
}
