import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'

import {App} from './App'
import {LoadingWrapper} from './components/common/LoadingWrapper'

ReactDOM.render(
  <BrowserRouter>
    <LoadingWrapper>
      <App />
    </LoadingWrapper>
  </BrowserRouter>,
  document.getElementById('root'),
)
