import {Info} from '@mui/icons-material'
import {Box, IconButton, styled, Tooltip, tooltipClasses} from '@mui/material'
import React, {type ReactFragment} from 'react'

const InfoIconTooltip = styled(({title}: {title: ReactFragment}) => (
  <IconButton sx={{'&:hover': {backgroundColor: 'transparent'}}}>
    <Tooltip title={title}>
      <Info />
    </Tooltip>
  </IconButton>
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))

type InfoIconComponentProps = {
  infoText: ReactFragment
  children: JSX.Element | JSX.Element[]
}

export const InfoIconComponent = ({infoText, children}: InfoIconComponentProps) => {
  return (
    <Box sx={{flexDirection: 'row', alignItems: 'center'}}>
      {children}
      <InfoIconTooltip title={infoText} />
    </Box>
  )
}
