import React from 'react'

import type {InePrijmyCelkovyZiskData} from '../../types'
import {Table} from '../common/table/Table'

type InePrijmyCelkovyZiskProps = {
  dataId: string
  rows: InePrijmyCelkovyZiskData[]
  handleDataUpdate: (rows: InePrijmyCelkovyZiskData[]) => void
  title: string
  subtitle: string
  disabled: boolean
}

export const InePrijmyCelkovyZisk = ({
  dataId,
  rows,
  handleDataUpdate,
  title,
  subtitle,
  disabled,
}: InePrijmyCelkovyZiskProps) => {
  return (
    <Table
      dataId={dataId}
      rows={rows}
      handleDataUpdate={handleDataUpdate}
      title={title}
      subtitle={subtitle}
      disabled={disabled}
      sortBy="firmyIdentifikacne"
      columns={[
        {
          label: 'Identifikačné údaje firmy',
          property: 'firmyIdentifikacne',
          inputType: 'text',
          extraProps: {multiline: true, minRows: 3},
        },
        {
          label: 'Podiel vo firme %',
          property: 'podielFirme',
          inputType: 'text',
          extraProps: {type: 'number'},
        },
        {
          label: 'Priamy podiel %',
          property: 'priamyPodiel',
          inputType: 'text',
          extraProps: {type: 'number'},
        },
        {
          label: 'Nepriamy podiel %',
          property: 'nepriamyPodiel',
          inputType: 'text',
          extraProps: {type: 'number'},
        },
        {
          label: 'Výška zisku',
          property: 'vyskuZisku',
          inputType: 'number',
        },
        {
          label: 'Výška zaplatenej dane v rozsahu nároku na dividendy',
          property: 'vyskaDividendy',
          inputType: 'number',
        },
      ]}
    />
  )
}
