import {Box, Stack, TextField, Typography} from '@mui/material'
import React, {useEffect, useMemo, useState} from 'react'

import type {Data, DataItem} from '../../types'
import {otherIncomeTypes} from './InePrijmy'

const calculateTotalIncomes = (data: Data) => {
  // do NOT count "end of year" ones: obycajnyPrijemRowsPohladavky
  const allCommonInvoices = [
    ...(data.obycajnyPrijemRowsPohladavkyZMinulehoRoku ?? []),
    ...(data.obycajnyPrijemRows ?? []),
  ]
  const totalCommon = allCommonInvoices.reduce((total, row) => total + Number(row.sumaNaFakture), 0)

  // do NOT count "end of year" ones: autorskyPrijemRowsPohladavky
  const allAuthorInvoices = [
    ...(data.autorskyPrijemRowsPohladavkyZMinulehoRoku ?? []),
    ...(data.autorskyPrijemRows ?? []),
  ]
  const totalAuthorActive = allAuthorInvoices.reduce((total, row) => total + Number(row.aktivne), 0)
  const totalAuthorPassive = allAuthorInvoices.reduce((total, row) => total + Number(row.pasivne), 0)

  return {
    totalCommon: totalCommon.toFixed(2),
    totalAuthorActive: totalAuthorActive.toFixed(2),
    totalAuthorPassive: totalAuthorPassive.toFixed(2),
    totalAuthor: (totalAuthorActive + totalAuthorPassive).toFixed(2),
  }
}

const calculateOtherTotals = (data: Data) => {
  let otherIncome = 0
  let otherExpenditure = 0

  otherIncomeTypes.forEach((dataKeyPrefix) => {
    const revenueKey = `${dataKeyPrefix}Prijmy` as const
    if (data[revenueKey] !== undefined) {
      otherIncome += Number(data[revenueKey])
    }

    const expenditureKey = `${dataKeyPrefix}Vydavky` as const
    if (data[expenditureKey] !== undefined) {
      otherExpenditure += Number(data[expenditureKey])
    }
  })
  return {
    otherIncome: otherIncome.toFixed(2),
    otherExpenditure: otherExpenditure.toFixed(2),
  }
}

type SpoluProps = {
  data: Data
  handleDataUpdate: (DataItem: DataItem) => void
}

export const Spolu = ({data, handleDataUpdate}: SpoluProps) => {
  const {totalCommon, totalAuthorActive, totalAuthorPassive, totalAuthor} = useMemo(
    () => calculateTotalIncomes(data),
    [data],
  )

  const [otherIncome, setOtherIncome] = useState((0).toFixed(2))
  const [otherExpenditure, setOtherExpenditure] = useState((0).toFixed(2))

  const [totalAttachments, setTotalAttachments] = useState(0)
  const [mandatoryAttachments, setMandatoryAttachments] = useState([0, 0])

  useEffect(() => {
    if (data.zoZivnostiPrijmy !== totalCommon) {
      handleDataUpdate({
        field: 'zoZivnostiPrijmy',
        value: totalCommon,
      })
    }
    if (data.zVytvoreniaDielaPrijmy !== totalAuthorActive) {
      handleDataUpdate({
        field: 'zVytvoreniaDielaPrijmy',
        value: totalAuthorActive,
      })
    }
    if (data.zPouzitiaDielaAUmeleckehoVykonuAkNepatriaDoPrijmovPodlaPrijmy !== totalAuthorPassive) {
      handleDataUpdate({
        field: 'zPouzitiaDielaAUmeleckehoVykonuAkNepatriaDoPrijmovPodlaPrijmy',
        value: totalAuthorPassive,
      })
    }
  }, [data, totalAuthorActive, totalAuthorPassive, totalCommon, handleDataUpdate])

  useEffect(() => {
    const otherTotals = calculateOtherTotals(data)

    if (otherTotals.otherIncome !== otherIncome) {
      setOtherIncome(otherTotals.otherIncome)
    }

    if (otherTotals.otherExpenditure !== otherExpenditure) {
      setOtherExpenditure(otherTotals.otherExpenditure)
    }
  }, [data, otherExpenditure, otherIncome])

  useEffect(() => {
    const otherGuardian = data.uplatnujeteSiDanovyBonusNaDieta && data.danovyBonusNaDetiInaOsoba

    // TODO extract and review this calculation
    const amountOfAttachments: number =
      (data.dpXML != null ? 1 : 0) +
      (data.dpPDF != null ? 1 : 0) +
      (data.uplatnujemSiDanovyBonusNaZaplateneUroky && data.potrvrdenieOZaplatenychUrokochOdBanky != null ? 1 : 0) +
      (data.matePreukazatelneZaplatenePrispevkyNaDoplnkoveDochodkoveSporenie && data.prispevkyNaDDP != null ? 1 : 0) +
      (data.maliStePrijemZoZavislejCinnosti && data.prijemZoZavislejCinnostiAttachment != null ? 1 : 0) +
      (data.maliStePrijemZoZavislejCinnostiZahranicie && data.prijemZoZavislejCinnostiAttachmentZahranicie != null
        ? 1
        : 0) +
      (data.inePrijmyDokumenty != null ? 1 : 0) +
      (data.splnamPodmienkyNaTriPercenta && data.splnamPodmienkyNaTriPercentaAttachment != null ? 1 : 0) +
      (data.uplatnujeteSiDanovyBonusNaDieta ? (data.danovyBonusNaDeti ?? []).length : 0) +
      (otherGuardian &&
      data.danovyBonusNaDetiInaOsobaTypDokladu !== 'rocne_zuctovanie_preddavkov' &&
      (data.danovyBonusNaDetiInaOsobaAttachment1 != null ||
        (data.danovyBonusNaDetiInaOsobaTypDokladu !== 'preukazana_vyska_zakladu_dane' &&
          data.danovyBonusNaDetiInaOsobaAttachment2 != null))
        ? 1
        : 0) +
      (otherGuardian && data.danovyBonusNaDetiInaOsobaRodnyListEtcAttachment != null ? 1 : 0)

    const mandatoryAttachmentsSubmited =
      amountOfAttachments -
      ((data.dpXML != null ? 1 : 0) +
        (data.dpPDF != null ? 1 : 0) +
        (data.inePrijmyDokumenty != null ? 1 : 0) +
        (otherGuardian && data.danovyBonusNaDetiInaOsobaRodnyListEtcAttachment != null ? 1 : 0))

    const amountOfMandatoryAttachments =
      (data.matePreukazatelneZaplatenePrispevkyNaDoplnkoveDochodkoveSporenie ? 1 : 0) +
      (data.uplatnujemSiDanovyBonusNaZaplateneUroky ? 1 : 0) +
      (data.maliStePrijemZoZavislejCinnosti ? 1 : 0) +
      (data.maliStePrijemZoZavislejCinnostiZahranicie ? 1 : 0) +
      (data.splnamPodmienkyNaTriPercenta ? 1 : 0) +
      (data.uplatnujeteSiDanovyBonusNaDieta ? (data.danovyBonusNaDeti ?? []).length : 0) +
      (otherGuardian && data.danovyBonusNaDetiInaOsobaTypDokladu !== 'dp_podane_v_sr' ? 1 : 0)

    setTotalAttachments(amountOfAttachments)
    setMandatoryAttachments([mandatoryAttachmentsSubmited, amountOfMandatoryAttachments])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Stack m={2}>
      <Typography key={'typography1'} data-testid="typography" variant="h4" component="div">
        Spolu
      </Typography>
      <Box key={'box1'} sx={{my: 2}}>
        <Typography key={'typography2'} data-testid="typography" variant="h6" component="div">
          AUTORSKÝ PRÍJEM (väčšinou developeri)
        </Typography>
        <Typography key={'typography3'} data-testid="typography" variant="body1" component="div">
          Aktívne (vytvorenie diela): {totalAuthorActive} €
        </Typography>
        <Typography key={'typography4'} data-testid="typography" variant="body1" component="div">
          Pasívne (licencia): {totalAuthorPassive} €
        </Typography>
        <Typography key={'typography5'} data-testid="typography" variant="body1" component="div">
          Suma na faktúre: {totalAuthor} €
        </Typography>
      </Box>
      <Box key={'box2'} sx={{my: 2}}>
        <Typography key={'typography6'} data-testid="typography" variant="h6" component="div">
          OBYČAJNÝ PRÍJEM (neautorský)
        </Typography>
        <Typography key={'typography7'} data-testid="typography" variant="body1" component="div">
          Suma: {totalCommon} €
        </Typography>
      </Box>
      <Box key={'box3'} sx={{my: 2}}>
        <Typography key={'typography8'} data-testid="typography" variant="h6" component="div">
          INÝ DRUH ČINNOSTI
        </Typography>
        <Typography key={'typography9'} data-testid="typography" variant="body1" component="div">
          Príjmy: {otherIncome} €
        </Typography>
        <Typography key={'typography10'} data-testid="typography" variant="body1" component="div">
          Výdavky: {otherExpenditure} €
        </Typography>
      </Box>
      <Box key={'box4'} sx={{my: 2}}>
        <Typography key={'typography11'} data-testid="typography" variant="h6" component="div">
          PRÍLOHY
        </Typography>
        <Typography key={'typography12'} data-testid="typography" variant="body1" component="div">
          Všetky: {totalAttachments}
        </Typography>
        <Typography key={'typography13'} data-testid="typography" variant="body1" component="div">
          Povinné: {mandatoryAttachments[0]}/{mandatoryAttachments[1]}
        </Typography>
      </Box>
      <Box key={'box5'} sx={{my: 2}}>
        <Typography key={'typography14'} data-testid="typography" variant="h6" component="div">
          Poznámky
        </Typography>
        <TextField
          id="outlined-textarea"
          label="Priestor na poznámky k Vášmu daňovému priznaniu"
          placeholder="Notes"
          multiline
          minRows={2}
          sx={{width: '100%', minWidth: 800}}
          value={data.notes}
          disabled={data.viewOnly}
          onChange={(event): void => {
            handleDataUpdate({
              field: 'notes',
              value: event.target.value,
            })
          }}
        />
      </Box>
    </Stack>
  )
}
