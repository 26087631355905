import {Box, Button, darken, FormControlLabel, lighten, Switch} from '@mui/material'
import {
  type GridColumns,
  type GridValueGetterSimpleParams,
  DataGrid,
  gridClasses,
  GridToolbarContainer,
} from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {rowsPerPageConstant, rowsPerPageOptionsConstant} from '../../constants'
import {archiveUsers, returnReconciliations} from '../../helpers/firebaseHelpers'
import {useLoading} from '../../helpers/loading'
import type {Data, RedirectState} from '../../types'

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'Email',
    width: 300,
  },
  {
    field: 'jiraId',
    headerName: 'Vacuum ID (Jira ID)',
    width: 200,
  },
  {
    field: 'statusPDF',
    headerName: 'Status',
    width: 180,
    valueGetter: (params: {row: Data}): string =>
      ` ${(params.row.status ?? '').replace('_', ' ')} ${params.row.dpPDF !== undefined ? 'PDF' : ''}`,
  },
  {
    field: 'approvalStatus1',
    headerName: 'Approval 1',
    width: 250,
    valueFormatter: ({value}) => value?.toString().replace('_', ' '),
    valueGetter: (params: {row: Data}): string => {
      if (params.row.approvalStatus1status === 'COMPLETED' || params.row.approvalStatus1status === 'REJECTED') {
        return params.row.approvalStatus1status ?? ''
      } else {
        return params.row.approvalStatus1 ?? ''
      }
    },
  },
  {
    field: 'approvalStatus2',
    headerName: 'Approval 2',
    width: 250,
    valueFormatter: ({value}) => value?.toString().replace('_', ' '),
    valueGetter: (params: {row: Data}): string => {
      if (params.row.approvalStatus2status === 'COMPLETED' || params.row.approvalStatus2status === 'REJECTED') {
        return params.row.approvalStatus2status ?? ''
      } else {
        return params.row.approvalStatus2 ?? ''
      }
    },
  },
  {
    field: 'approvalStatus3',
    headerName: 'Approval 3',
    width: 250,
    valueFormatter: ({value}) => value?.toString().replace('_', ' '),
    valueGetter: (params: {row: Data}): string => {
      if (params.row.approvalStatus3status === 'COMPLETED' || params.row.approvalStatus3status === 'REJECTED') {
        return params.row.approvalStatus3status ?? ''
      } else {
        return params.row.approvalStatus3 ?? ''
      }
    },
  },
  {
    field: 'submittedAt',
    headerName: 'Submission Date',
    width: 200,
    valueFormatter: (params) => params.value?.toLocaleString('sk'),
    valueGetter: (params: GridValueGetterSimpleParams<Data>) =>
      params.row.updateHistory?.length &&
      new Date(params.row.updateHistory[params.row.updateHistory.length - 1]!.updatedAt),
  },
  {
    field: 'term',
    headerName: 'Term',
    width: 250,
  },
]

const coeficient = 0.1
const getHoverBackgroundColor = (color: string, mode: string): string =>
  mode === 'dark' ? lighten(color, coeficient) : darken(color, coeficient)

type ReconciliationsProps = {
  data: Data[]
  handleShowAllChange: (isChecked: boolean) => void
  showAll: boolean
  handleShowArchivedChange: (isChecked: boolean) => void
  showArchived: boolean
  isAdmin: boolean
}

export const Reconciliations = ({
  data,
  handleShowAllChange,
  showAll,
  handleShowArchivedChange,
  showArchived,
  isAdmin,
}: ReconciliationsProps) => {
  const [rows, setRows] = useState<Data[]>([])
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
  const [pageSize, setPageSize] = useState(rowsPerPageConstant)

  const navigate = useNavigate()
  const {setLoading} = useLoading()

  useEffect(() => {
    if (rows !== data) {
      setRows(data)
    }
  }, [data, rows])

  const onShowAllChange = (_: unknown, value: boolean): void => {
    if (showAll !== value) {
      handleShowAllChange(value)
    }
  }

  const onShowArchivedChange = (_: unknown, value: boolean): void => {
    if (showArchived !== value) {
      handleShowArchivedChange(value)
    }
  }

  const getRedirectState = (viewOnly: boolean | undefined): RedirectState | undefined => {
    if (selectedRowIds.length !== 1) {
      return undefined
    }
    const reconciliation = rows.filter((row) => selectedRowIds[0] === row.id)[0]
    if (reconciliation === undefined) {
      return undefined
    }
    return {reconciliation, viewOnly: viewOnly === undefined ? true : viewOnly}
  }

  const finalColumns = [...columns]
  if (isAdmin) {
    finalColumns.push({
      field: 'isArchived',
      headerName: 'Archived',
      valueGetter: (params: {row: Data}) => (params.row.isArchived ? 'true' : 'false'),
    })
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box>
          <Button
            disabled={selectedRowIds.length !== 1}
            onClick={(): void =>
              navigate('../slovakia', {
                replace: true,
                state: getRedirectState(true),
              })
            }
          >
            View
          </Button>
          <Button
            disabled={selectedRowIds.length !== 1}
            onClick={(): void =>
              navigate('../slovakia', {
                replace: true,
                state: getRedirectState(false),
              })
            }
          >
            Edit
          </Button>
          <Button
            data-testid="return-to-user-button"
            disabled={selectedRowIds.length === 0}
            onClick={async () => {
              try {
                setLoading(true)
                await returnReconciliations(selectedRowIds, rows)
              } catch (e) {
                // eslint-disable-next-line no-console
                console.warn(e)
              } finally {
                setLoading(false)
              }
            }}
          >
            Return to user
          </Button>
          {isAdmin && (
            <Button
              disabled={selectedRowIds.every((rowId: string) => rows.find((r) => r.id === rowId)?.isArchived)}
              onClick={async () => {
                try {
                  setLoading(true)
                  const selectedNonArchivedRecords = selectedRowIds
                    .map((rowId: string) => rows.find((r) => r.id === rowId) as Data)
                    .filter((r) => !r?.isArchived)
                  const isConfirmed = window.confirm(
                    `Are you sure you want to archive ${selectedNonArchivedRecords.length} user(s)?`,
                  )

                  if (isConfirmed) {
                    await archiveUsers(
                      selectedNonArchivedRecords.map((r) => r?.id ?? ''),
                      rows,
                    )
                  }
                  // eslint-disable-next-line no-empty
                } catch (e) {
                  //
                } finally {
                  setLoading(false)
                }
              }}
            >
              Archive user
            </Button>
          )}
        </Box>
        <div>
          {isAdmin && (
            <FormControlLabel
              control={<Switch checked={showArchived} onChange={onShowArchivedChange} />}
              label="Show archived"
            />
          )}
          <FormControlLabel control={<Switch checked={showAll} onChange={onShowAllChange} />} label="Show all" />
        </div>
      </GridToolbarContainer>
    )
  }
  return (
    <div style={{height: 600, width: '100%'}}>
      <DataGrid
        sx={{
          '& .MuiDataGrid-toolbarContainer': {
            justifyContent: 'space-between',
          },
          '& .row-status--NEW': {
            bgcolor: '#FFFFFF',
            '&:hover': {
              bgcolor: (theme): string => getHoverBackgroundColor('#FFFFFF', theme.palette.mode),
            },
          },
          '& .row-status--IN_PROGRESS': {
            bgcolor: '#FFF77D',
            color: '#4F470D',
            '&:hover': {
              bgcolor: (theme): string => getHoverBackgroundColor('#FFF77D', theme.palette.mode),
            },
          },
          '& .row-status--COMPLETED': {
            bgcolor: '#DDF9E3',
            color: '#47654A',
            '&:hover': {
              bgcolor: (theme): string => getHoverBackgroundColor('#DDF9E3', theme.palette.mode),
            },
          },
          '& .row-status--ERROR': {
            bgcolor: '#FBAAA3',
            color: '#6B0A03',
            '&:hover': {
              bgcolor: (theme): string => getHoverBackgroundColor('#FBAAA3', theme.palette.mode),
            },
          },
          '& .row-status--GENERATED': {
            bgcolor: '#3f51b5',
            '&:hover': {
              bgcolor: (theme): string => getHoverBackgroundColor('#3f51b5', theme.palette.mode),
            },
          },
          '& .row-status--SUBMITTED': {
            bgcolor: (theme): string => theme.palette.warning.main,
            '&:hover': {
              bgcolor: (theme): string => getHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode),
            },
          },
          '& .row-status--APPROVED': {
            bgcolor: (theme): string => theme.palette.info.main,
            '&:hover': {
              bgcolor: (theme): string => getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
            },
          },
          '& .row-is-archived--true': {
            color: (theme): string => theme.palette.grey['500'],
          },
        }}
        rows={rows}
        columns={finalColumns}
        columnBuffer={finalColumns.length + 1}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={rowsPerPageOptionsConstant}
        getRowClassName={(params): string =>
          `row-status--${params.row.status as string} row-is-archived--${params.row.isArchived ? 'true' : 'false'}`
        }
        checkboxSelection
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        onSelectionModelChange={(selectionModel, _details): void => {
          setSelectedRowIds(selectionModel as string[])
        }}
      />
    </div>
  )
}
