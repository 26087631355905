import {type SelectChangeEvent, Button, FormControl, InputLabel, MenuItem, Select, Stack} from '@mui/material'
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useTaxYear} from '../utils'

type CountrySelectProps = {
  reloadReconciliation: (taxYear: number) => Promise<boolean>
}

export const CountrySelect = ({reloadReconciliation}: CountrySelectProps) => {
  const navigate = useNavigate()

  const taxYear = useTaxYear()

  const [country, setCountry] = useState<string>('slovakia')

  const handleChange = (event: SelectChangeEvent<string>): void => {
    setCountry(event.target.value)
  }

  const handleConfirm = async () => {
    const loaded = await reloadReconciliation(taxYear)
    if (loaded) {
      navigate(country)
    }
  }

  return (
    <Stack alignItems="center" direction="row" spacing={1} p={2} justifyContent="center" flex={1}>
      <FormControl>
        <InputLabel id="country-select-label">Country</InputLabel>
        <Select
          sx={{bgcolor: '#fff'}}
          labelId="country-select-label"
          id="country-select"
          value={country}
          label="Country"
          onChange={handleChange}
          size="small"
        >
          <MenuItem value="slovakia">Slovakia</MenuItem>
          <MenuItem value="hungary">Hungary</MenuItem>
          <MenuItem value="czechia">Czech Republic</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" size="small" disabled={country !== 'slovakia'} onClick={handleConfirm}>
        Confirm
      </Button>
    </Stack>
  )
}
