import {Grid, Stack} from '@mui/material'
import React, {useMemo} from 'react'

import {AddUserRole} from '../components/admin/AddUserRole'
import {CreateEmptyProfile} from '../components/admin/CreateEmptyProfile'
import {MembershipTable} from '../components/admin/MembershipTable'
import {MigrateEmail} from '../components/admin/MigrateEmail'
import {ResetProfiles} from '../components/admin/ResetProfiles'
import {useTaxYear} from '../utils'

type AdminProps = {
  membership: {
    finance: string[]
    admin: string[]
  }
}

export const Admin = ({membership: {finance, admin}}: AdminProps) => {
  const taxYear = useTaxYear()

  const rows = useMemo(() => {
    const emails = [...new Set([...finance, ...admin])].sort()

    return emails.map((email, i) => ({
      id: String(i),
      email,
      role: [admin.includes(email) ? 'ADMIN' : null, finance.includes(email) ? 'FINANCE' : null]
        .filter(Boolean)
        .join(', '),
    }))
  }, [finance, admin])

  return (
    <Grid container columnSpacing={2} p={2}>
      <Grid item xs={8}>
        <MembershipTable rows={rows} />
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={3}>
          <ResetProfiles taxYear={taxYear} />
          <MigrateEmail taxYear={taxYear} />
          <CreateEmptyProfile taxYear={taxYear} />
          <AddUserRole role="admin" />
          <AddUserRole role="finance" />
        </Stack>
      </Grid>
    </Grid>
  )
}
