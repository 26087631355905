import {Button} from '@mui/material'
import React from 'react'

import {resetErrors} from '../../helpers/firebaseHelpers'
import {useLoading} from '../../helpers/loading'

type ResetProfilesProps = {
  taxYear: number
}

export const ResetProfiles = ({taxYear}: ResetProfilesProps) => {
  const {setLoading} = useLoading()

  return (
    <Button
      key="reset-profiles-reset"
      data-testid="reset-profiles-reset-button"
      variant="contained"
      color="primary"
      onClick={(): void => {
        setLoading(true)
        resetErrors(taxYear)
          .then((_res) => {
            alert('Reset errors in profiles')
          })
          .catch((e) => {
            alert(e)
          })
          .finally(() => setLoading(false))
      }}
    >
      Reset errors in {taxYear} profiles
    </Button>
  )
}
