import {Box, Checkbox, FormControlLabel, Stack, Typography} from '@mui/material'
import React from 'react'

import {uploadToStorage} from '../../helpers/firebaseHelpers'
import type {DanovyBonusNaDetiData} from '../../types'
import {SingleFileUpload} from './SingleFileUpload'

type BirthCertificateUploaderProps = {
  dataId: string
  value: DanovyBonusNaDetiData
  onChange: (value: DanovyBonusNaDetiData) => void
}

const buildFileName = (data: DanovyBonusNaDetiData) =>
  [
    data.meno.replace(/\s/g, ''),
    data.priezvisko.replace(/\s/g, ''),
    data.rodneCisloPredLomitkom,
    data.rodneCisloZaLomitkom,
  ].join('_')

export const BirthCertificateUploader = ({dataId, value, onChange}: BirthCertificateUploaderProps) => {
  if (value.certificatesAlreadySubmitted == null) {
    value.certificatesAlreadySubmitted = false
  }
  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value.certificatesAlreadySubmitted}
            onChange={({target}) => onChange({...value, certificatesAlreadySubmitted: target.checked})}
          />
        }
        label="Rodný list môjho dieťaťa už bol poskytnutý v minulých rokoch"
      />
      {!value.certificatesAlreadySubmitted && (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1"> Priložiť rodný list </Typography>
          <SingleFileUpload
            label="Rodný list"
            existingAttachment={value.rodnyList}
            disabled={
              value.rodneCisloPredLomitkom.length === 0 ||
              value.rodneCisloZaLomitkom.length === 0 ||
              value.meno.length === 0 ||
              value.priezvisko.length === 0
            }
            fileChangeFunc={async (file) => {
              await uploadToStorage(file, `rodneListy/${buildFileName(value)}`, dataId).then((downloadURL) => {
                onChange({...value, rodnyList: downloadURL})
              })
            }}
          />
        </Stack>
      )}
    </Box>
  )
}
