import {TableCell} from '@mui/material'
import React from 'react'

import {formatCurrency} from '../../../utils'

type TableNumberCellProps = {
  amount: string
}

export const TableNumberCell = ({amount}: TableNumberCellProps) => {
  return <TableCell align="right">{formatCurrency(amount)}</TableCell>
}
