import {Box, Typography} from '@mui/material'
import React from 'react'

type StatisticForStatusProps = {
  count: number
  label: string
}

export const StatisticForStatus = ({count, label}: StatisticForStatusProps) => {
  return (
    <Box
      boxShadow="rgb(145 158 171 / 48%) 0px 0px 1px 0px, rgb(145 158 171 / 24%) 0px 2px 4px -1px"
      sx={{paddingY: 1, paddingX: 1.5, width: 140}}
      bgcolor="white"
      borderRadius={1}
    >
      <Typography variant="body1" fontWeight="600" fontSize={12} color="gray">
        {label}
      </Typography>
      <Typography variant="h5" component="p" align="right" fontWeight="600">
        {count}
      </Typography>
    </Box>
  )
}
