import {Box} from '@mui/system'
import React from 'react'

// TODO: Add more helpful text to not found page.
export const NotFound = () => {
  return (
    <Box position="absolute" right={0} bottom={0}>
      <style>
        {`
          @keyframes slide {
            0% {
              transform: translateY(100%);
            }
          
            100% {
              transform: translateY(0);
            }
          }
          `}
      </style>
      <img
        src="/404-trex.png"
        alt="not found"
        style={{display: 'block', animationName: 'slide', animationDuration: '2s', width: 300}}
      />
    </Box>
  )
}
