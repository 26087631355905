import {MenuItem, TextField} from '@mui/material'
import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {monthIds, monthLabels} from '../../constants'
import type {Poistenie} from '../../types'
import {useTaxYear} from '../../utils'

type PeriodSelectorProps = {
  value: Pick<Poistenie, 'period'>
  onChange: (value: Pick<Poistenie, 'period'>) => void
}

const buildMonthItem = (monthIndex: number, year: string) => ({
  value: `${monthIds[monthIndex]}-${year}`,
  label: `${monthLabels[monthIndex]} ${year}`,
})

const buildMonthItems = (fullTaxYear: number) => {
  // generate months from `dec-{yy-1}` to `dec-{yy}`, inclusive
  // e.g. for 2023 produces values
  // [ 'dec-22', 'jan-23', 'feb-23', ..., 'nov-23', 'dec-23' ]

  const taxYear = fullTaxYear.toString().substring(2)
  const prevYear = (fullTaxYear - 1).toString().substring(2)

  return [buildMonthItem(monthIds.length - 1, prevYear), ...monthIds.map((_, i) => buildMonthItem(i, taxYear))]
}

export const PeriodSelector = ({value: {period}, onChange}: PeriodSelectorProps) => {
  const taxYear = useTaxYear()
  const monthItems = useMemo(() => buildMonthItems(taxYear), [taxYear])

  const [paidIn, setPaidIn] = useState('')
  const [paidFor, setPaidFor] = useState('')

  useEffect(() => {
    const [paidInMonth, paidInYear, paidForMonth, paidForYear] = period.split('-')
    setPaidIn(paidInMonth && paidInYear ? `${paidInMonth}-${paidInYear}` : '')
    setPaidFor(paidForMonth && paidForYear ? `${paidForMonth}-${paidForYear}` : '')
  }, [period])

  const handleChange = useCallback(
    (newPaidIn: string | null, newPaidFor: string | null) => {
      if (newPaidIn != null) {
        setPaidIn(newPaidIn)
        if (!paidFor) {
          // defaults to previous month
          const itemIndex = monthItems.findIndex(({value}) => value === newPaidIn)
          newPaidFor = monthItems[itemIndex - 1]?.value ?? null
        }
      }
      if (newPaidFor != null) {
        setPaidFor(newPaidFor)
      }

      const newPeriod =
        (newPaidIn ?? paidIn) && (newPaidFor ?? paidFor) ? `${newPaidIn ?? paidIn}-${newPaidFor ?? paidFor}` : ''

      if (newPeriod !== period) {
        onChange({period: newPeriod})
      }
    },
    [monthItems, onChange, paidFor, paidIn, period],
  )
  return (
    <>
      <TextField
        key="paid-in"
        select
        label="Zaplatené v mesiaci"
        value={paidIn}
        onChange={(e) => {
          handleChange(e.target.value, null)
        }}
        required
      >
        {monthItems.slice(1).map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        key="paid-for"
        select
        label="Zaplatené za mesiac"
        value={paidFor}
        onChange={(e) => {
          handleChange(null, e.target.value)
        }}
        required
      >
        {monthItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}
