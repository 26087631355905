import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import React from 'react'

import {uploadToStorage} from '../../helpers/firebaseHelpers'
import type {OtherGuardianDocumentType} from '../../types'
import {SingleFileUpload} from '../common/SingleFileUpload'

type OtherGuardianDocumentsProps = {
  dataId: string
  documentType?: OtherGuardianDocumentType
  documentUrl1: string | null | undefined
  documentUrl2: string | null | undefined
  onChange: (documentType: OtherGuardianDocumentType) => void
  onUpload: (
    fileId: 'danovyBonusNaDetiInaOsobaAttachment1' | 'danovyBonusNaDetiInaOsobaAttachment2',
    documentUrl: string,
  ) => void
  disabled?: boolean
}

export const OtherGuardianDocuments = ({
  dataId,
  documentType,
  documentUrl1,
  documentUrl2,
  onChange,
  onUpload,
  disabled,
}: OtherGuardianDocumentsProps) => {
  return (
    <FormControl>
      <RadioGroup
        value={documentType}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.value as unknown as OtherGuardianDocumentType)
        }
      >
        <FormControlLabel
          value="dp_podane_v_sr"
          control={<Radio />}
          label="Oznamujem, že druhá oprávnená osoba podala daňové priznanie na území SR"
          disabled={disabled ?? false}
        />
        <FormControlLabel
          value="rocne_zuctovanie_preddavkov"
          control={<Radio />}
          label="Kópia dokladu o vykonanom ročnom zúčtovaní preddavkov na daň z príjmov zo závislej činnosti druhej oprávnenej osoby"
          disabled={disabled ?? false}
        />
        {documentType === 'rocne_zuctovanie_preddavkov' && (
          <SingleFileUpload
            label="Kópia dokladu"
            existingAttachment={documentUrl1 ?? null}
            disabled={disabled ?? false}
            fileChangeFunc={async (file) => {
              await uploadToStorage(file, 'danovyBonusNaDetiInaOsobaAttachment1', dataId).then((downloadURL) => {
                onUpload('danovyBonusNaDetiInaOsobaAttachment1', downloadURL)
              })
            }}
            fileId="danovyBonusNaDetiInaOsobaAttachment1"
          />
        )}
        <FormControlLabel
          value="preukazana_vyska_zakladu_dane"
          control={<Radio />}
          label="Kópia dokladu preukazujúceho výšku základu dane podľa §5 zákona druhej oprávnenej osoby, ktorej ročné zúčtovanie nebolo vykonané, a nemala povinnosť podať daňové priznanie"
          disabled={disabled ?? false}
        />
        {documentType === 'preukazana_vyska_zakladu_dane' && (
          <SingleFileUpload
            label="Kópia dokladu"
            existingAttachment={documentUrl2 ?? null}
            disabled={disabled ?? false}
            fileChangeFunc={async (file) => {
              await uploadToStorage(file, 'danovyBonusNaDetiInaOsobaAttachment2', dataId).then((downloadURL) => {
                onUpload('danovyBonusNaDetiInaOsobaAttachment2', downloadURL)
              })
            }}
            fileId="danovyBonusNaDetiInaOsobaAttachment2"
          />
        )}
      </RadioGroup>
    </FormControl>
  )
}
