import React from 'react'

import type {DanovyBonusNaDetiData} from '../../types'
import {generateMonths} from '../../utils'
import {BirthCertificateUploader} from '../common/BirthCertificateUploader'
import {MonthsDisplay} from '../common/MonthsDisplay'
import {MonthsSelector} from '../common/MonthsSelector'
import {Table} from '../common/table/Table'

type DanovyBonusNaDetiProps = {
  dataId: string
  rows: DanovyBonusNaDetiData[]
  handleDataUpdate: (data: DanovyBonusNaDetiData[]) => void
  disabled?: boolean
}

export const DanovyBonusNaDeti = ({dataId, rows, handleDataUpdate, disabled}: DanovyBonusNaDetiProps) => {
  return (
    <Table
      dataId={dataId}
      rows={rows}
      handleDataUpdate={handleDataUpdate}
      title="Daňový bonus na deti"
      disabled={disabled ?? false}
      hideFooter
      sortBy="rodneCisloPredLomitkom"
      columns={[
        {
          label: 'Meno',
          property: 'meno',
          inputType: 'text',
        },
        {
          label: 'Priezvisko',
          property: 'priezvisko',
          inputType: 'text',
        },
        {
          label: 'Rodné číslo pred lomítkom',
          property: 'rodneCisloPredLomitkom',
          inputType: 'text',
          isValid: (row) => /^\d{6}$/.test(row.rodneCisloPredLomitkom),
        },
        {
          label: 'Rodné číslo za lomítkom',
          property: 'rodneCisloZaLomitkom',
          inputType: 'text',
          isValid: (row) => /^\d{4}$/.test(row.rodneCisloZaLomitkom),
        },
        {
          label: 'Mesiace',
          property: 'mesiace',
          inputType: 'custom',
          getDefault: () => generateMonths(false),
          isValid: (row) => Object.values(row.mesiace).some(Boolean),
          customCellRenderer: (row) => <MonthsDisplay months={row.mesiace} />,
          CustomModalEditor: MonthsSelector,
        },
        {
          label: 'Rodný list',
          property: 'rodnyList',
          inputType: 'custom',
          getDefault: () => null,
          isValid: (row) => row.rodnyList != null || row.certificatesAlreadySubmitted,
          customCellRenderer: (row) => (row.rodnyList || row.certificatesAlreadySubmitted ? 'OK' : 'Chýba'),
          CustomModalEditor: BirthCertificateUploader,
        },
      ]}
    />
  )
}
