import {type Dispatch, type SetStateAction, createContext, useContext} from 'react'

const LoadingContext = createContext<{
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}>({
  loading: false,
  setLoading: () => {},
})

export const LoadingContextProvider = LoadingContext.Provider

export const useLoading = () => useContext(LoadingContext)
