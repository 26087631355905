import React from 'react'

import type {AutorskyPrijemData} from '../../types'
import {Table} from '../common/table/Table'

type AutorskyPrijemProps = {
  dataId: string
  rows: AutorskyPrijemData[]
  handleDataUpdate: (rows: AutorskyPrijemData[]) => void
  title: string
  subtitle: string
  disabled: boolean
}

export const AutorskyPrijem = ({dataId, rows, handleDataUpdate, title, subtitle, disabled}: AutorskyPrijemProps) => {
  return (
    <Table
      dataId={dataId}
      rows={rows}
      handleDataUpdate={handleDataUpdate}
      title={title}
      subtitle={subtitle}
      disabled={disabled}
      sortBy="datumUhrady"
      columns={[
        {
          label: 'Číslo FA',
          property: 'cisloFA',
          inputType: 'text',
        },
        {
          label: 'Aktívne (vytvorenie diela)',
          property: 'aktivne',
          inputType: 'number',
        },
        {
          label: 'Pasívne (licencia)',
          property: 'pasivne',
          inputType: 'number',
        },
        {
          label: 'Suma na faktúre',
          property: 'sumaNaFakture',
          inputType: 'number',
        },
        {
          label: 'Dátum vystavenia',
          property: 'datumSplatnosti',
          inputType: 'date',
        },
        {
          label: 'Dátum úhrady',
          property: 'datumUhrady',
          inputType: 'date',
        },
      ]}
    />
  )
}
