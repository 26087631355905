export const rowsPerPageConstant = 12
export const rowHeight = 53
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const rowsPerPageOptionsConstant: number[] = [5, 10, 12, 25]
export const drawerWidth = 240

export const monthIds = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'] as const
export const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'] as const

export const minEmailLength = 5
