import {initializeApp} from 'firebase/app'
import type {User} from 'firebase/auth'
import {getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup} from 'firebase/auth'
import type {DocumentData} from 'firebase/firestore'
import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'
import {useState} from 'react'

import type {Data, DataStub, FirebaseConfig} from './../types'

const OLDEST_RECON_YEAR = 2021

const firebaseConfig: FirebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY!,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN!,
  projectId: process.env.REACT_APP_PROJECT_ID!,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET!,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID!,
  appId: process.env.REACT_APP_APP_ID!,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID ?? '',
}

if (firebaseConfig.measurementId === '') {
  delete firebaseConfig.measurementId
}

initializeApp(firebaseConfig)
const auth = getAuth()
const storage = getStorage()
const db = getFirestore()
const provider = new GoogleAuthProvider()

export function useAuth(): {user: string | null; userObject: User | null} {
  const [userObject, setUserObject] = useState<User | null>(null)

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      if (userObject !== user) {
        setUserObject(user)
      }
    } else {
      // User is signed out
      setUserObject(null)
    }
  })

  return {user: userObject?.email ?? null, userObject}
}

export async function migrateUserProfile(
  taxYear: number,
  currentEmail: string,
  newEmail: string,
  updateContactEmail: boolean,
): Promise<string | true> {
  try {
    const docRef = doc(db, getCollectionName(taxYear), currentEmail)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const dataNew = docSnap.data()
      dataNew.id = newEmail
      if (newEmail.endsWith('@vacuumlabs.com')) {
        dataNew.vlEmail = newEmail
      }
      if (updateContactEmail) {
        dataNew.kontaktEmail = newEmail
      }
      await setDoc(doc(db, getCollectionName(taxYear), newEmail), dataNew)
      await deleteDoc(docRef)

      return true
    } else {
      // doc.data() will be undefined in this case
      return 'Profile ' + currentEmail + ' not found. Unable to migrate.'
    }
  } catch (e) {
    return e as string
  }
}

export async function getMembership(area: 'Finance' | 'Admin'): Promise<DocumentData> {
  if (auth.currentUser) {
    const docRef = doc(db, 'access_management', area)
    try {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        return docSnap.data()
      } else {
        throw new Error('No data found')
      }
    } catch (e) {
      throw new Error(e as string)
    }
  } else {
    throw new Error('User not logged in')
  }
}

export async function adminCreateEmptyProfile(taxYear: number, email: string): Promise<string> {
  if (email.length > 0) {
    const profile = {
      dic: '',
      skNACE: '',
      firstName: '',
      lastName: '',
      kontaktEmail: email,
      vlEmail: email,
      id: email,
      autorskyPrijemRows: [],
      autorskyPrijemRowsPohladavky: [],
      autorskyPrijemRowsPohladavkyZMinulehoRoku: [],
      country: 'SK',
      danovyBonusNaDeti: [],
      chcemPoukazatDvePercenta: true,
      jiraId: '',
      kontaktIBAN: '',
      kontaktTelCislo: '',
      obycajnyPrijemRows: [],
      obycajnyPrijemRowsPohladavky: [],
      obycajnyPrijemRowsPohladavkyZMinulehoRoku: [],
      socialnePoistenie: [],
      inePrijmyCelkovyZisk: [],
      ico: '',
      status: 'NEW',
      zdravotnePoistenie: [],
      trvalyPobytObec: '',
      trvalyPobytUlica: '',
      trvalyPobytSupisneCislo: '',
      trvalyPobytOrientacneCislo: '',
      trvalyPobytPSC: '',
      trvalyPobytStat: '',
      zdravotnePoistenieNedoplatok: [],
      isArchived: false,
    }

    try {
      await setDoc(doc(db, getCollectionName(taxYear), email), profile)
      return 'Success'
    } catch (e) {
      return e as string
    }
  } else {
    throw new Error('Email is empty')
  }
}

export async function adminAddUser(type = 'finance', email: string): Promise<string> {
  let folderRef = null
  if (type === 'finance') {
    try {
      folderRef = doc(db, 'access_management', `Finance`)
      await updateDoc(folderRef, {
        users: arrayUnion(email),
      })
      return 'Added new finance user'
    } catch (e) {
      return e as string
    }
  } else if (type === 'admin') {
    try {
      folderRef = doc(db, 'access_management', `Admin`)
      await updateDoc(folderRef, {
        users: arrayUnion(email),
      })
      return 'Added new admin user'
    } catch (e) {
      return e as string
    }
  } else {
    throw new Error('Invalid type')
  }
}

export async function loadReconciliation(taxYear: number): Promise<Data | null> {
  const docId = getUserDocId()
  const docRef = doc(db, getCollectionName(taxYear), docId)
  try {
    const document = await getDoc(docRef)
    if (document.exists()) {
      return {
        id: document.id,
        ...document.data(),
        taxYear,
      } as Data
    } else {
      // eslint-disable-next-line no-console
      console.warn('No reconciliation found for', taxYear, auth.currentUser?.email)
      return null
    }
  } catch (e) {
    throw new Error(e as string)
  }
}

export async function loadAllReconciliationStubsPerUser(fromTaxYear: number): Promise<DataStub[]> {
  const results: DataStub[] = []
  for (let year = fromTaxYear; year >= OLDEST_RECON_YEAR; year--) {
    const data = await loadReconciliation(year)
    if (data) {
      results.push({
        taxYear: data.taxYear,
        status: data.status,
        id: data.id,
      })
    }
  }
  return results
}

export function returnReconciliations(ids: string[], rows: Data[]): Promise<Data[]> {
  const filteredRows = rows.filter((row) => ids.includes(row.id))
  return Promise.all(
    filteredRows.map((row) => {
      row.status = 'IN_PROGRESS'
      return updateReconciliation(row, row.id) // add admin id instead of undefined when supported
    }),
  )
}

export async function archiveUsers(ids: string[], rows: Data[]): Promise<void> {
  const filteredRows = rows.filter((row) => ids.includes(row.id))
  await Promise.all(
    filteredRows.map((row) => {
      row.isArchived = true
      return updateReconciliation(row, row.id)
    }),
  )
}

async function changeReconciliation(status: string, data: Data, id: string): Promise<Data> {
  const docRef = doc(db, getCollectionName(data.taxYear), id)
  const {...newData} = data
  newData.status = status

  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await updateDoc(docRef, newData as {[Property in keyof Data]: any})
    return newData
  } catch (e) {
    throw new Error('Cannot update reconciliation ' + e)
  }
}

export function updateReconciliation(data: Data, id: string) {
  // if update is triggered by admin, provide id of the document
  return changeReconciliation(
    data.status === 'NEW' || data.status === undefined ? 'IN_PROGRESS' : data.status,
    data,
    id,
  )
}

export async function submitReconciliation(data: Data, id: string) {
  // if update is triggered by admin, provide id of the document
  return changeReconciliation(
    'SUBMITTED',
    {
      ...data,
      updateHistory: [
        ...(data.updateHistory ?? []),
        {
          updatedBy: getUserDocId(), // not passing `id` here, this is the logged user who pressed the button
          updatedAt: new Date().toISOString(),
        },
      ],
    },
    id,
  )
}

export async function resetErrors(taxYear: number): Promise<void> {
  const docsToUpdate: string[] = []
  try {
    const q = query(collection(db, getCollectionName(taxYear)), where('status', '==', 'ERROR'))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((d) => {
      // doc.data() is never undefined for query doc snapshots
      docsToUpdate.push(d.id)
    })
    // update status
    await Promise.all(
      docsToUpdate.map(async (docId) => {
        const docRef = doc(db, getCollectionName(taxYear), docId)
        await updateDoc(docRef, {
          status: 'APPROVED',
        })
      }),
    )
  } catch (e) {
    throw new Error(e as string)
  }
}

export async function loadAllReconciliations(taxYear: number, showAll = false): Promise<Data[]> {
  if (!auth.currentUser) {
    throw new Error('No id provided and not logged in.')
  }
  try {
    const reconcilliations: Data[] = []
    let q = null
    if (showAll) {
      q = query(collection(db, getCollectionName(taxYear)))
    } else {
      q = query(
        collection(db, getCollectionName(taxYear)),
        where('status', 'in', ['SUBMITTED', 'APPROVED', 'COMPLETED', 'GENERATED']),
      )
    }
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((document) => {
      // doc.data() is never undefined for query doc snapshots
      const tempData = {...document.data(), id: document.id, taxYear} as Data
      reconcilliations.push(tempData)
    })
    return reconcilliations
  } catch (e) {
    throw new Error(e as string)
  }
}

export async function uploadToStorage(
  file: Blob | Uint8Array | ArrayBuffer,
  fileName: string,
  id: string,
): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileRef = ref(storage, `${id}/${fileName}`)
    uploadBytes(fileRef, file)
      .then(() => {
        getDownloadURL(fileRef)
          .then((url) => {
            resolve(url)
          })
          .catch((e) => {
            reject(e)
          })
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export function signInWithGoogle(): Promise<void> {
  return signInWithPopup(auth, provider)
    .then((_snapshot) => {
      // empty
    })
    .catch((_error) => {
      // continue regardless of error
    })
}

export function signOutUser(): Promise<string> {
  return new Promise((resolve, reject) => {
    auth
      .signOut()
      .then(() => {
        resolve('Signed out.')
      })
      .catch((e) => {
        reject(e as string)
      })
  })
}

const getCollectionName = (taxYear: number) => taxYear.toString()

const getUserDocId = (id?: string) => {
  if (id != null) {
    return id
  }
  if (auth.currentUser?.email == null) {
    throw new Error('No ID provided and not logged in.')
  }
  return auth.currentUser.email
}
