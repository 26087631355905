import {Cancel} from '@mui/icons-material'
import {
  type SxProps,
  Button,
  IconButton,
  Modal as MuiModal,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, {useEffect, useState} from 'react'

import type {ColumnType, RowType} from './Table'

const root: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  p: 2,
}

const inputProps = {
  text: {},
  number: {
    min: 0,
  },
  date: {
    min: '2015-01-01',
    max: '2050-01-01',
  },
} as const

type ModalProps<Row extends RowType> = {
  open: boolean
  columns: Array<ColumnType<Row>>
  initialData: Row
  defaultData: Row
  handleModalClose: () => void
  handleModalConfirm: (row: Row) => void
  dataId: string
}

export const Modal = <Row extends RowType>({
  open,
  columns,
  initialData,
  defaultData,
  handleModalClose,
  handleModalConfirm,
  dataId,
}: ModalProps<Row>) => {
  const [data, setData] = useState<Row>(initialData)

  useEffect(() => {
    setData(initialData)
  }, [initialData])

  // TODO consider using react-hook-form for more robust validation
  const isValid = columns.every((column) => {
    const value = data[column.property]
    return column.isValid ? column.isValid(data) : value != null && value !== ''
  })

  return (
    <MuiModal
      open={open}
      onClose={() => {
        setData(defaultData)
        handleModalClose()
      }}
      disableAutoFocus
      data-testid="table-modal"
    >
      <Paper sx={root} variant="outlined">
        <Stack sx={{mb: 3}} direction="row" alignItems="center" justifyContent="space-between">
          <Typography sx={{pl: '4px'}} variant="h6">
            Záznam
          </Typography>
          <Tooltip title="Zatvoriť">
            <IconButton
              onClick={() => {
                setData(defaultData)
                handleModalClose()
              }}
            >
              <Cancel />
            </IconButton>
          </Tooltip>
        </Stack>

        <Stack sx={{mb: 3}} component="form" gap={{xs: 3, sm: 4}}>
          {columns.map((column) => {
            if (column.inputType === 'custom') {
              const CustomModalEditor = column.CustomModalEditor
              return CustomModalEditor ? (
                <CustomModalEditor
                  key={column.property}
                  dataId={dataId}
                  value={data}
                  onChange={(changedValue) => setData({...data, ...changedValue})}
                />
              ) : null
            }
            return (
              <TextField
                key={column.property}
                label={column.label}
                type={column.inputType}
                value={data[column.property]}
                onChange={(e) => {
                  setData({...data, [column.property]: e.target.value})
                }}
                inputProps={inputProps[column.inputType]}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                {...column.extraProps}
              />
            )
          })}
        </Stack>

        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button
            variant="text"
            onClick={() => {
              setData(defaultData)
              handleModalClose()
            }}
          >
            Zrušiť
          </Button>
          <Button variant="contained" disabled={!isValid} onClick={() => handleModalConfirm(data)}>
            Potvrdiť
          </Button>
        </Stack>
      </Paper>
    </MuiModal>
  )
}
