import {
  AdminPanelSettings,
  CloudDownloadRounded,
  LogoutRounded,
  PlayArrowRounded,
  SupervisorAccount,
} from '@mui/icons-material'
import {Box, Button, Stack, Typography} from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom'

import {signOutUser} from '../helpers/firebaseHelpers'
import {useLoading} from '../helpers/loading'
import type {Data} from '../types'

type HomeProps = {
  data: Data
  user: string
  currentTaxYear: number
  isFinance?: boolean
  isAdmin?: boolean
}

export const Home = ({data, user, currentTaxYear, isFinance, isAdmin}: HomeProps) => {
  const navigate = useNavigate()
  const {loading, setLoading} = useLoading()

  if (loading) {
    return null
  }

  if (data.status == null) {
    return (
      <Stack alignItems="center" spacing={2} flex={1} justifyContent="center" mx={3}>
        <Typography variant="h5">
          There is no {currentTaxYear} reconciliation for <strong>{user}.</strong>
        </Typography>
        <Typography>
          Your reconciliation does not exist, please check if you logged in with the correct email.
          <br />
          If that does not help, please reach out to the Finance team.
        </Typography>
        {isFinance || isAdmin ? (
          <Stack spacing={2}>
            {isFinance && (
              <Button
                variant="contained"
                onClick={() => navigate(`/${currentTaxYear}/finance`)}
                startIcon={<SupervisorAccount />}
              >
                Finance portal
              </Button>
            )}
            {isAdmin && (
              <Button
                variant="contained"
                onClick={() => navigate(`/${currentTaxYear}/admin`)}
                startIcon={<AdminPanelSettings />}
              >
                Admin portal
              </Button>
            )}
          </Stack>
        ) : (
          <Button
            variant="contained"
            onClick={(): void => {
              setLoading(true)
              signOutUser()
                .then(() => {
                  navigate('/')
                })
                .catch((e) => alert(e))
                .finally(() => setLoading(false))
            }}
            endIcon={<LogoutRounded />}
          >
            Logout
          </Button>
        )}
      </Stack>
    )
  }

  return (
    <Stack alignItems="center" spacing={2} flex={1} justifyContent="center" mx={3}>
      <Typography variant="h6">
        Your {data.taxYear} reconciliation status is <strong>{data.status.replace('_', ' ').toLowerCase()}</strong>.
      </Typography>

      {data.status === 'NEW' && (
        <Box>
          <Typography>
            Your tax reconciliation data is prepared, please fill out all applicable fields in the form.
          </Typography>
          <Button
            variant="contained"
            onClick={(): void => {
              navigate(`/${data.taxYear}`)
            }}
            endIcon={<PlayArrowRounded />}
          >
            START
          </Button>
        </Box>
      )}

      {data.status === 'COMPLETED' && (
        <Stack alignItems="center" spacing={2}>
          <Typography>Your documents are ready. Please do not forget to</Typography>
          <ol>
            <li>
              <strong>submit</strong> your Tax Declaration to the Financial Office (Finančná správa)
              <br /> - XML and all attachments
            </li>
            <li>
              and also <strong>pay</strong> the tax
            </li>
          </ol>
          <Typography>
            by 31.3.{currentTaxYear} (or 30.6.{currentTaxYear} for an extended period).
          </Typography>
          <Stack direction="row" spacing={1}>
            {data.dpPDF && (
              <Button
                variant="contained"
                component="a"
                href={data.dpPDF}
                target="_blank"
                rel="noopener noreferrer"
                download={`dpPDF${currentTaxYear}.pdf`}
                title="Download PDF"
                startIcon={<CloudDownloadRounded />}
              >
                PDF
              </Button>
            )}
            {data.dpXML && (
              <Button
                variant="contained"
                component="a"
                href={data.dpXML}
                target="_blank"
                rel="noopener noreferrer"
                download={`dpXML${currentTaxYear}.xml`}
                title="Download XML"
                startIcon={<CloudDownloadRounded />}
              >
                XML
              </Button>
            )}
          </Stack>
          <Typography textAlign="center">
            To download the XML, use right-click and &quot;Save link as ...&quot;
            <br />
            (or similar, depending on your browser).
          </Typography>
        </Stack>
      )}

      {data.status === 'IN_PROGRESS' && (
        <Stack alignItems="center" spacing={2}>
          <Typography>
            Please finish it before <strong>9th February {data.taxYear + 1}</strong> for standard term, or before{' '}
            <strong>15th March {data.taxYear + 1}</strong> for extended.
          </Typography>
          <Button
            variant="contained"
            onClick={(): void => {
              navigate(`/${data.taxYear}/slovakia`)
            }}
            endIcon={<PlayArrowRounded />}
          >
            Continue
          </Button>
        </Stack>
      )}

      {data.status === 'SUBMITTED' && (
        <Typography>
          You have submitted your reconciliation, in case you have any questions, or need to update your data, please
          contact the finance team.
        </Typography>
      )}

      {data.status === 'APPROVED' && (
        <Typography>
          Your tax reconciliation has been reviewed and approved, you`&apos;`ll be notified once it`&apos;`s completed.
        </Typography>
      )}
    </Stack>
  )
}
