import {AdminPanelSettings, ListAlt, LoginRounded, SupervisorAccount} from '@mui/icons-material'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {type Location, Route, Routes, useLocation, useNavigate} from 'react-router-dom'

import {Header} from './components/Header'
import {drawerWidth} from './constants'
import {
  getMembership,
  loadAllReconciliationStubsPerUser,
  loadReconciliation,
  signInWithGoogle,
  updateReconciliation,
  useAuth,
} from './helpers/firebaseHelpers'
import {useLoading} from './helpers/loading'
import {Admin} from './screens/Admin'
import {CountrySelect} from './screens/CountrySelect'
import {Finance} from './screens/Finance'
import {Home} from './screens/Home'
import {NotFound} from './screens/NotFound'
import {SlovakiaFlow} from './screens/SlovakiaFlow'
import type {Data, DataItem, DataStub, RedirectState} from './types'

const isViewOnly = (data: Data, editableTaxYear: number, isFinance?: boolean) => {
  if (data.taxYear !== editableTaxYear) {
    return true
  }
  if (isFinance) {
    return false // finance can edit regardless of state
  }
  return !['NEW', 'IN_PROGRESS'].includes(data.status ?? '')
}

export const App = () => {
  const currentTaxYear = new Date().getFullYear() - 1 // hardcode another date here for experiments
  const {loading, setLoading} = useLoading()

  const {user, userObject} = useAuth()
  const navigate = useNavigate()

  const [data, setData] = useState<Data>({viewOnly: false, taxYear: currentTaxYear, id: ''})
  const [adminMembership, setAdminMembership] = useState<string[]>([])
  const [financeMembership, setFinanceMembership] = useState<string[]>([])
  const [allStubs, setAllStubs] = useState<DataStub[]>([])

  const isFinance = user !== null && financeMembership.includes(user)
  const isAdmin = user !== null && adminMembership.includes(user)

  useEffect(() => {
    if (user !== null) {
      setLoading(true)
      Promise.all([
        loadAllReconciliationStubsPerUser(currentTaxYear)
          .then((stubs) => {
            setAllStubs(stubs)
          })
          .catch((e) => {
            alert(e)
          }),
        loadReconciliation(currentTaxYear)
          .then((newData) => {
            if (newData) {
              setData({...newData, viewOnly: isViewOnly(newData, currentTaxYear)})
            }
          })
          .catch((e) => {
            alert(e)
          }),
        getMembership('Finance')
          .then((newData) => {
            setFinanceMembership((newData.users as string[]) ?? [])
          })
          .catch(() => {
            // TODO: Add errors layer.
          }),
        getMembership('Admin')
          .then((newData) => {
            setAdminMembership((newData.users as string[]) ?? [])
          })
          .catch(() => {
            // TODO: Add errors layer.
          }),
      ])
        .catch(() => {})
        .finally(() => {
          setLoading(false)
        })
    }
  }, [user, currentTaxYear, setLoading])

  const location: Location = useLocation()
  const state = location.state as RedirectState | undefined

  const path = window.location.pathname
  const isAdminPath = path.includes('/admin')
  // If the displayed reconciliation is not for logged user it means you used View/Edit on finance portal
  const isFinancePath = path.includes('/finance') || (!isAdminPath && data.id !== user)

  useEffect(() => {
    if (
      state?.reconciliation &&
      (data.id !== state.reconciliation.id ||
        data.viewOnly !== state.viewOnly ||
        data.taxYear !== state.reconciliation.taxYear)
    ) {
      setData({
        ...state.reconciliation,
        viewOnly: state.viewOnly || isViewOnly(state.reconciliation, currentTaxYear, isFinancePath),
      })
    }
  }, [currentTaxYear, data, isFinancePath, state])

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Stack flex={1}>
      <Toolbar />
      <Divider />
      {user !== null && (
        <Stack flex={1}>
          <List>
            {allStubs.map((stub) => (
              <ListItem
                key={stub.taxYear.toString()}
                button
                onClick={() => navigate(`/${stub.taxYear}`)}
                selected={!isFinancePath && !isAdminPath && path.includes(`/${stub.taxYear}`)}
              >
                <ListItemIcon>
                  <ListAlt color={stub.taxYear === currentTaxYear ? 'primary' : 'disabled'} />
                </ListItemIcon>
                <ListItemText primary={stub.taxYear} secondary={stub.status?.replace('_', ' ').toLowerCase()} />
              </ListItem>
            ))}

            {isFinance && (
              <ListItem
                button
                onClick={() => {
                  navigate(`/${currentTaxYear}/finance`)
                }}
                selected={isFinancePath}
              >
                <ListItemIcon>
                  <SupervisorAccount color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Finance" />
              </ListItem>
            )}

            {isAdmin && (
              <ListItem
                button
                onClick={() => {
                  navigate(`/${currentTaxYear}/admin`)
                }}
                selected={isAdminPath}
              >
                <ListItemIcon>
                  <AdminPanelSettings color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Admin" />
              </ListItem>
            )}
          </List>
        </Stack>
      )}
    </Stack>
  )

  const container = window !== undefined ? (): Element => window.document.body : null

  return (
    <Box display="flex" sx={{minHeight: '100vh'}}>
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />

      <Box>
        <Header
          user={userObject}
          drawerWidth={drawerWidth}
          uid={user}
          isFinance={isFinance}
          isAdmin={isAdmin}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box component="nav" sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: {xs: 'block', sm: 'none'},
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: {xs: 'none', sm: 'block'},
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <Stack
        component="main"
        sx={{
          flexGrow: 1,
          width: {sm: `calc(100% - ${drawerWidth}px)`},
        }}
        // TODO: Comment or define as constant so it's not removed later on.
        bgcolor="rgb(249, 250, 251)"
      >
        {/* NOTE: Toolbar is used as spacer at the top as it has same height as the header.
          Content added to the first Toolbar won't be visible as it would be positioned below header  */}
        <Toolbar />
        {user === null ? (
          <Stack alignItems="center" spacing={2} flex={1} justifyContent="center">
            <Typography>You need to login before continuing.</Typography>
            <Button variant="contained" onClick={signInWithGoogle} endIcon={<LoginRounded />}>
              Login
            </Button>
          </Stack>
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                <Home data={data} user={user} isFinance={isFinance} isAdmin={isAdmin} currentTaxYear={currentTaxYear} />
              }
            />
            <Route path=":taxYear">
              <Route
                index
                element={
                  <CountrySelect
                    reloadReconciliation={async (taxYear) => {
                      if (user && (data.id !== user || data.taxYear !== taxYear)) {
                        setLoading(true)
                        try {
                          const newData = await loadReconciliation(taxYear)
                          if (newData == null) {
                            return false
                          }
                          setData({
                            ...newData,
                            viewOnly: isViewOnly(newData, currentTaxYear, isFinancePath),
                          })
                        } catch (e) {
                          // setData({})
                          alert(e)
                          return false
                        }
                        setLoading(false)
                      }
                      return true
                    }}
                  />
                }
              />
              <Route
                path="slovakia"
                element={
                  <SlovakiaFlow
                    drawerWidth={drawerWidth}
                    data={data}
                    isFinance={isFinance}
                    isAdmin={isAdmin}
                    financeMembers={financeMembership}
                    currentTaxYear={currentTaxYear}
                    handleDataUpdate={(item: DataItem) => {
                      const newData = {
                        ...data,
                        [item.field]: item.value,
                      }
                      if (item.field === 'status') {
                        newData.viewOnly = isViewOnly(newData, currentTaxYear)
                        // update status also in left panel
                        const stub = allStubs.find(({taxYear}) => newData.taxYear === taxYear)
                        if (stub) {
                          stub.status = newData.status ?? ''
                          setAllStubs(allStubs)
                        }
                      }
                      setData(newData)

                      if (item.forceSave ?? false) {
                        setLoading(true)
                        updateReconciliation({...data, [item.field]: item.value}, data.id)
                          .catch((e) => {
                            alert(e)
                          })
                          .finally(() => setLoading(false))
                      }
                    }}
                  />
                }
              />
              <Route path="finance" element={<Finance isAdmin={isAdmin} />} />
              <Route
                path="admin"
                element={
                  isAdmin ? (
                    <Admin
                      membership={{
                        finance: financeMembership,
                        admin: adminMembership,
                      }}
                    />
                  ) : (
                    // TODO: Replace with error component.
                    <Typography>You are not an admin</Typography>
                  )
                }
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </Stack>
    </Box>
  )
}
