import {Box, Typography} from '@mui/material'
import React from 'react'

import {monthIds, monthLabels} from '../../constants'
import type {Months} from '../../types'

type MonthsDisplayProps = {
  months: Months
}

export const MonthsDisplay = ({months}: MonthsDisplayProps) => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      {monthIds.map((monthId, i) => (
        <Typography
          key={`${monthId}-label`}
          sx={{
            p: 1,
            backgroundColor: months[monthId] ? '#90EE90' : 'inherit',
            border: '1px solid grey',
            borderLeft: i === 0 ? null : '0px',
          }}
        >
          {monthLabels[i]}
        </Typography>
      ))}
    </Box>
  )
}
