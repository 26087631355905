import {Box, Checkbox, FormControlLabel, FormGroup} from '@mui/material'
import React from 'react'

import {monthIds, monthLabels} from '../../constants'
import type {DanovyBonusNaDetiData} from '../../types'
import {generateMonths} from '../../utils'

type MonthsData = Pick<DanovyBonusNaDetiData, 'mesiace' | 'vsetkyMesiace'>

type MonthsSelectorProps = {
  value: MonthsData
  onChange: (value: MonthsData) => void
  monthsPerRow?: number
  disabled?: boolean
}

export const MonthsSelector = ({value, onChange, monthsPerRow, disabled}: MonthsSelectorProps) => {
  if (value.vsetkyMesiace == null) {
    value.vsetkyMesiace = false
  }
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const gridColCount = monthsPerRow ?? 4
  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <FormGroup sx={{display: 'flex', flexDirection: 'row'}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={value.vsetkyMesiace}
              onChange={({target}) =>
                onChange({
                  mesiace: generateMonths(target.checked),
                  vsetkyMesiace: target.checked,
                })
              }
              disabled={disabled ?? false}
            />
          }
          label="Celý rok"
        />
      </FormGroup>
      {}
      <FormGroup sx={{display: 'grid', gridTemplateColumns: `repeat(${gridColCount}, 1fr)`}}>
        {monthIds.map((monthId, i) => (
          <FormControlLabel
            key={monthId}
            control={
              <Checkbox
                checked={value.mesiace[monthId]}
                onChange={({target}) =>
                  onChange({
                    mesiace: {...value.mesiace, [monthId]: target.checked},
                    vsetkyMesiace: value.vsetkyMesiace && target.checked,
                  })
                }
                disabled={disabled ?? false}
              />
            }
            label={monthLabels[i] ?? ''}
          />
        ))}
      </FormGroup>
    </Box>
  )
}
