import {FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography} from '@mui/material'
import React from 'react'

import {uploadToStorage} from '../../helpers/firebaseHelpers'
import type {Data, DataItem} from '../../types'
import {SingleFileUpload} from '../common/SingleFileUpload'

const statuses = ['COMPLETED', 'IN_PROGRESS', 'REJECTED']

type ApprovalFlowProps = {
  data: Data
  handleDataUpdate: (DataItem: DataItem) => void
  financeMembers: string[]
}

export const ApprovalFlow = ({data, handleDataUpdate, financeMembers}: ApprovalFlowProps) => {
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography data-testid="typography-finance" variant="h6" component="p">
          Approval process
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Approval #1</InputLabel>
          <Select
            label="Approval status"
            value={data.approvalStatus1 ?? ''}
            onChange={(event): void => {
              handleDataUpdate({
                field: 'approvalStatus1',
                value: event.target.value,
                forceSave: true,
              })
            }}
          >
            {financeMembers.map((member: string) => (
              <MenuItem key={member} value={member}>
                {member}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Select
          fullWidth
          label="Approval status"
          value={data.approvalStatus1status ?? ''}
          onChange={(event): void => {
            handleDataUpdate({
              field: 'approvalStatus1status',
              value: event.target.value,
              forceSave: true,
            })
          }}
        >
          {statuses.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Approval #2</InputLabel>
          <Select
            value={data.approvalStatus2 ?? ''}
            onChange={(event): void => {
              handleDataUpdate({
                field: 'approvalStatus2',
                value: event.target.value,
                forceSave: true,
              })
            }}
          >
            {financeMembers.map((member: string) => (
              <MenuItem key={member} value={member}>
                {member}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Select
          fullWidth
          label="Approval status"
          sx={{marginTop: 1}}
          value={data.approvalStatus2status ?? ''}
          onChange={(event): void => {
            handleDataUpdate({
              field: 'approvalStatus2status',
              value: event.target.value,
              forceSave: true,
            })
          }}
        >
          {statuses.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Approval #3</InputLabel>
          <Select
            label="Approval status"
            value={data.approvalStatus3 ?? ''}
            onChange={(event): void => {
              handleDataUpdate({
                field: 'approvalStatus3',
                value: event.target.value,
                forceSave: true,
              })
            }}
          >
            {financeMembers.map((member: string) => (
              <MenuItem key={member} value={member}>
                {member}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Select
          fullWidth
          label="Approval status"
          sx={{marginTop: 1}}
          value={data.approvalStatus3status ?? ''}
          onChange={(event): void => {
            handleDataUpdate({
              field: 'approvalStatus3status',
              value: event.target.value,
              forceSave: true,
            })
          }}
        >
          {statuses.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack spacing={1}>
        <Typography key={'typography6'} data-testid="typography-finance" variant="h6" component="div">
          Poznámky danovnika
        </Typography>
        <TextField
          key={'textField1'}
          id="outlined-textarea"
          label="Priestor na poznámky danovnika"
          placeholder="Notes"
          multiline
          disabled={true}
          sx={{width: '100%', minWidth: 800}}
          value={data.notes ?? ''}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography key={'typography7'} data-testid="typography-finance" variant="h6" component="div">
          Poznámky finance
        </Typography>
        <TextField
          key={'textField2'}
          id="outlined-textarea"
          label="Priestor na finance poznámky"
          placeholder="Notes"
          multiline
          disabled={false}
          sx={{width: '100%', minWidth: 800}}
          value={data.notes_finance}
          onChange={(event): void => {
            handleDataUpdate({
              field: 'notes_finance',
              value: event.target.value,
            })
          }}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography data-testid="typography-finance" variant="h6" component="div">
          Manual Submission
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1">Daňové priznanie XML</Typography>
          <SingleFileUpload
            data-testid="upload"
            existingAttachment={data.dpXML ?? null}
            label="DP XML"
            fileChangeFunc={async (file): Promise<void> => {
              await uploadToStorage(file, 'dpXML', data.id).then((downloadURL) => {
                handleDataUpdate({
                  field: 'dpXML',
                  value: downloadURL,
                  forceSave: true,
                })
              })
            }}
            fileId="dpXML"
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1">Daňové priznanie PDF</Typography>
          <SingleFileUpload
            data-testid="upload"
            existingAttachment={data.dpPDF ?? null}
            label="DP PDF"
            fileChangeFunc={async (file): Promise<void> => {
              await uploadToStorage(file, 'dpPDF', data.id).then((downloadURL) => {
                handleDataUpdate({
                  field: 'dpPDF',
                  value: downloadURL,
                  forceSave: true,
                })
              })
            }}
            fileId="dpPDF"
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
