import {Button, Checkbox, FormControlLabel, TextField} from '@mui/material'
import React, {useState} from 'react'

import {minEmailLength} from '../../constants'
import {migrateUserProfile} from '../../helpers/firebaseHelpers'
import {useLoading} from '../../helpers/loading'
import {AdminWrap} from './AdminWrap'

type MigrateEmailProps = {
  taxYear: number
}

export const MigrateEmail = ({taxYear}: MigrateEmailProps) => {
  const {setLoading} = useLoading()
  const [value, setValue] = useState({
    currentEmail: '',
    newEmail: '',
    updateContactEmail: false,
  })

  return (
    <AdminWrap
      title={`Migrate ${taxYear} profile`}
      actions={
        <>
          <Button
            data-testid="migrate-email-reset-button"
            variant="text"
            onClick={(): void => {
              setValue({
                currentEmail: '',
                newEmail: '',
                updateContactEmail: false,
              })
            }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            data-testid="migrate-email-save-button"
            disabled={value.currentEmail.length < minEmailLength || value.newEmail.length < minEmailLength}
            onClick={() => {
              setLoading(true)
              migrateUserProfile(taxYear, value.currentEmail, value.newEmail, value.updateContactEmail)
                .then((res) => {
                  if (res === true) {
                    alert(`Migrated profile from ${value.currentEmail} to ${value.newEmail}.`)
                    setValue({
                      currentEmail: '',
                      newEmail: '',
                      updateContactEmail: false,
                    })
                  } else {
                    alert(res)
                    // don't clear the form if error
                  }
                })
                .catch((e) => {
                  alert(e)
                })
                .finally(() => setLoading(false))
            }}
          >
            Migrate
          </Button>
        </>
      }
    >
      <TextField
        fullWidth
        required
        size="small"
        label="Current Email"
        type="email"
        value={value.currentEmail}
        onChange={(e) => setValue({...value, currentEmail: e.target.value})}
      />
      <TextField
        required
        fullWidth
        size="small"
        label="New Email"
        type="email"
        onChange={(e) => setValue({...value, newEmail: e.target.value})}
        value={value.newEmail}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={value.updateContactEmail}
            onChange={(e) => setValue({...value, updateContactEmail: e.target.checked})}
          />
        }
        label="Update contact email"
      />
    </AdminWrap>
  )
}
