import {Logout, Menu} from '@mui/icons-material'
import {AppBar, Avatar, Button, IconButton, Stack, Toolbar, Typography} from '@mui/material'
import type {User} from 'firebase/auth'
import React, {type MouseEventHandler} from 'react'
import {useNavigate} from 'react-router-dom'

import {signInWithGoogle, signOutUser} from '../helpers/firebaseHelpers'
import {useLoading} from '../helpers/loading'

type HeaderProps = {
  uid: string | null
  user: User | null
  drawerWidth: number | string
  handleDrawerToggle: MouseEventHandler<HTMLButtonElement>
  isFinance?: boolean
  isAdmin?: boolean
}

export const Header = ({uid, user, drawerWidth, handleDrawerToggle, isFinance, isAdmin}: HeaderProps) => {
  const navigate = useNavigate()
  const {setLoading} = useLoading()

  const roles = [isFinance ? 'Finance' : null, isAdmin ? 'Admin' : null].filter(Boolean)

  return (
    <AppBar
      position="fixed"
      sx={{
        width: {sm: `calc(100% - ${drawerWidth}px)`},
        // ml: {sm: `${drawerWidth}px`},
        backdropFilter: 'blur(6px)',
        backgroundColor: '#f5f5fbcc',
      }}
      elevation={0}
      color="default"
    >
      <Toolbar>
        <IconButton
          sx={{display: {xs: 'block', sm: 'none'}, marginRight: 1}}
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <Menu />
        </IconButton>

        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
          <span>🦖</span> T-Rex: Tax reconciliation platform
        </Typography>
        {uid !== null ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack>
              <Typography variant="h6">{uid}</Typography>
              {roles.length > 0 ? <Typography variant="subtitle1">Role: {roles.join(', ')}</Typography> : null}
            </Stack>
            {user !== null && (
              <>{user.photoURL !== null && <Avatar alt={user.displayName ?? ''} src={user.photoURL} />}</>
            )}
            <Button
              onClick={(): void => {
                setLoading(true)
                signOutUser()
                  .then(() => {
                    navigate('/')
                  })
                  .catch((_e) => {
                    // empty
                  })
                  .finally(() => setLoading(false))
              }}
              color="inherit"
              endIcon={<Logout />}
            >
              Logout
            </Button>
          </Stack>
        ) : (
          <Button
            onClick={async () => {
              setLoading(true)
              await signInWithGoogle().finally(() => setLoading(false))
            }}
            color="inherit"
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}
