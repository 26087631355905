import React from 'react'

import type {Poistenie} from '../../types'
import {formatPeriod, toNumericPeriod} from '../../utils'
import {PeriodSelector} from '../common/PeriodSelector'
import {Table} from '../common/table/Table'

type OdvodyProps = {
  dataId: string
  rows: Poistenie[]
  handleDataUpdate: (rows: Poistenie[]) => void
  title: string
  subtitle: string
  disabled: boolean
}

export const Odvody = ({dataId, rows, handleDataUpdate, title, subtitle, disabled}: OdvodyProps) => {
  const enhancedRows = rows.map((row) => ({
    ...row,
    periodNumeric: toNumericPeriod(row.period),
  }))
  return (
    <Table
      dataId={dataId}
      rows={enhancedRows}
      handleDataUpdate={handleDataUpdate}
      title={title}
      subtitle={subtitle}
      disabled={disabled}
      sortBy="periodNumeric"
      columns={[
        {
          label: 'Obdobie',
          property: 'period',
          inputType: 'custom',
          isValid: (row) => !!row.period,
          customCellRenderer: (row) => formatPeriod(row.period),
          CustomModalEditor: PeriodSelector,
        },
        {
          label: 'Suma',
          property: 'suma',
          inputType: 'number',
        },
      ]}
    />
  )
}
