import {TableCell, TableFooter as MuiTableFooter, TableRow} from '@mui/material'
import React, {type ReactNode} from 'react'

import {formatCurrency} from '../../../utils'
import type {ColumnType, RowType} from './Table'

type TableFooterCellProps = {
  align?: 'left' | 'right'
  children?: ReactNode
}

const TableFooterCell = ({align = 'left', children}: TableFooterCellProps) => (
  <TableCell sx={{border: 'none'}} align={align} variant="footer">
    {children}
  </TableCell>
)

type TableFooterProps<Row extends RowType> = {
  rows: Row[]
  columns: Array<ColumnType<Row>>
}

export const TableFooter = <Row extends RowType>({rows, columns}: TableFooterProps<Row>) => {
  return (
    <MuiTableFooter>
      <TableRow>
        <TableFooterCell>Spolu</TableFooterCell>
        {columns.slice(1).map((column) =>
          column.inputType === 'number' ? (
            <TableFooterCell align="right" key={column.property}>
              {formatCurrency(rows.map((row) => Number(row[column.property])).reduce((a, b) => a + b))}
            </TableFooterCell>
          ) : (
            <TableFooterCell key={column.property} />
          ),
        )}
      </TableRow>
    </MuiTableFooter>
  )
}
