import React from 'react'
import type {Data, DataItem} from 'types'

import {generateMonths} from '../../utils'
import {BirthNumberFields} from '../common/BirthNumberFields'
import {MonthsSelector} from '../common/MonthsSelector'
import type {FlowDescription} from '../common/types'
import {AutorskyPrijem} from './AutorskyPrijem'
import {DanovyBonusNaDeti} from './DanovyBonusNaDeti'
import {InePrijmy} from './InePrijmy'
import {InePrijmyCelkovyZisk} from './InePrijmyCelkovyZisk'
import {ObycajnyPrijem} from './ObycajnyPrijem'
import {Odvody} from './Odvody'
import {OtherGuardianDocuments} from './OtherGuardianDocuments'

type SkStep =
  | 'basic-information'
  | 'lowering-base-of-tax'
  | 'incomes-and-expenses'
  | '2-percent'
  | 'other-incomes'
  | 'summary'

export const getCustomFields = (
  viewOnly: boolean,
  data: Data,
  handleDataUpdate: (dataItem: DataItem) => void,
  taxYear: number,
) => ({
  danovyBonusNaDeti: () => (
    <DanovyBonusNaDeti
      dataId={data.id}
      disabled={viewOnly}
      rows={data.danovyBonusNaDeti ?? []}
      handleDataUpdate={(rows) => {
        handleDataUpdate({
          field: 'danovyBonusNaDeti',
          value: rows,
          forceSave: true,
        })
      }}
    />
  ),
  danovyBonusNaDetiInaOsobaRodneCislo: () => (
    <BirthNumberFields
      beforeSlashDataKey="danovyBonusNaDetiInaOsobaRodneCisloPredLomitkom"
      afterSlashDataKey="danovyBonusNaDetiInaOsobaRodneCisloZaLomitkom"
      data={data}
      handleDataUpdate={handleDataUpdate}
      title="Rodné číslo druhej oprávnenej osoby"
      disabled={viewOnly}
    />
  ),
  danovyBonusNaDetiInaOsobaMesiace: () => (
    <MonthsSelector
      disabled={viewOnly}
      monthsPerRow={6}
      value={
        data.danovyBonusNaDetiInaOsobaMesiace ?? {
          mesiace: generateMonths(false),
          vsetkyMesiace: false,
        }
      }
      onChange={(value) => {
        handleDataUpdate({
          field: 'danovyBonusNaDetiInaOsobaMesiace',
          value,
        })
      }}
    />
  ),
  danovyBonusNaDetiInaOsobaDoklady: () => (
    <OtherGuardianDocuments
      dataId={data.id}
      disabled={viewOnly}
      documentType={data.danovyBonusNaDetiInaOsobaTypDokladu ?? 'dp_podane_v_sr'}
      documentUrl1={data.danovyBonusNaDetiInaOsobaAttachment1}
      documentUrl2={data.danovyBonusNaDetiInaOsobaAttachment2}
      onChange={(value) => {
        handleDataUpdate({
          field: 'danovyBonusNaDetiInaOsobaTypDokladu',
          value,
        })
      }}
      onUpload={(fileId, downloadURL) => {
        handleDataUpdate({
          field: fileId,
          value: downloadURL,
          forceSave: true,
        })
      }}
    />
  ),
  autorskyPrijemRowsPohladavkyZMinulehoRoku: () => (
    <AutorskyPrijem
      dataId={data.id}
      disabled={viewOnly}
      rows={data.autorskyPrijemRowsPohladavkyZMinulehoRoku ?? []}
      title={`AUTORSKÝ PRÍJEM - Pohľadávky z minulého roka (vystavené ${taxYear - 1}, vyplatené ${taxYear})`}
      handleDataUpdate={(rows) => {
        handleDataUpdate({
          field: 'autorskyPrijemRowsPohladavkyZMinulehoRoku',
          value: rows,
          forceSave: false,
        })
      }}
      subtitle=""
    />
  ),
  autorskyPrijemRows: () => (
    <AutorskyPrijem
      dataId={data.id}
      disabled={viewOnly}
      rows={data.autorskyPrijemRows ?? []}
      title="AUTORSKÝ PRÍJEM"
      handleDataUpdate={(rows) => {
        handleDataUpdate({
          field: 'autorskyPrijemRows',
          value: rows,
          forceSave: false,
        })
      }}
      subtitle={`Tu prosím vyplňte príjem z vytvorenia diela alebo poskytnutia práv
      priemyselného alebo duševného vlastníctva (finálna suma na faktúre
      vrátane 2% do lit. fondu). Prosím uvádzajte príjmy, ktoré Vám v
      predchádzajúcom roku (${taxYear}) prišli na účet, teda faktúra sa môže týkať
      aj roku ${taxYear - 1}.`}
    />
  ),
  autorskyPrijemRowsPohladavky: () => (
    <AutorskyPrijem
      dataId={data.id}
      disabled={viewOnly}
      rows={data.autorskyPrijemRowsPohladavky ?? []}
      title={`AUTORSKÝ PRÍJEM - Pohľadávky na konci roka (vystavené ${taxYear}, vyplatené ${taxYear + 1})`}
      handleDataUpdate={(rows) => {
        handleDataUpdate({
          field: 'autorskyPrijemRowsPohladavky',
          value: rows,
          forceSave: false,
        })
      }}
      subtitle={`Tu prosím vyplňte príjem z vytvorenia diela alebo poskytnutia práv
      priemyselného alebo duševného vlastníctva (finálna suma na faktúre
      vrátane 2% do lit. fondu). Prosím uveďte faktúry, ktoré Vám boli v
      predchádzajúcom roku (${taxYear}) vystavené, ale prišli na účet až v roku
      ${taxYear + 1}. Tieto faktúry nebudú zahrnuté do príjmov, ale len do pohľadáviek
      na konci roka.`}
    />
  ),
  obycajnyPrijemRowsPohladavkyZMinulehoRoku: () => (
    <ObycajnyPrijem
      dataId={data.id}
      disabled={viewOnly}
      rows={data.obycajnyPrijemRowsPohladavkyZMinulehoRoku ?? []}
      title={`OBYČAJNÝ PRÍJEM - Pohľadávky z minulého roka (vystavené ${taxYear - 1}, vyplatené ${taxYear})`}
      handleDataUpdate={(rows) => {
        handleDataUpdate({
          field: 'obycajnyPrijemRowsPohladavkyZMinulehoRoku',
          value: rows,
          forceSave: false,
        })
      }}
      subtitle=""
    />
  ),
  obycajnyPrijemRows: () => (
    <ObycajnyPrijem
      dataId={data.id}
      disabled={viewOnly}
      rows={data.obycajnyPrijemRows ?? []}
      title="OBYČAJNÝ PRÍJEM"
      handleDataUpdate={(rows): void => {
        handleDataUpdate({
          field: 'obycajnyPrijemRows',
          value: rows,
          forceSave: false,
        })
      }}
      subtitle={`Tu vyplňte Vaše príjmy zo živnosti (faktúry), 
      ktoré ste dostali zaplatené v roku ${taxYear} okrem autorskych odmien 
      (za licenciu a vytvorenie diela)`}
    />
  ),
  obycajnyPrijemRowsPohladavky: () => (
    <ObycajnyPrijem
      dataId={data.id}
      disabled={viewOnly}
      rows={data.obycajnyPrijemRowsPohladavky ?? []}
      title={`OBYČAJNÝ PRÍJEM - Pohľadávky na konci roka (vystavené ${taxYear}, vyplatené ${taxYear + 1})`}
      handleDataUpdate={(rows): void => {
        handleDataUpdate({
          field: 'obycajnyPrijemRowsPohladavky',
          value: rows,
          forceSave: false,
        })
      }}
      subtitle={`Tu vyplňte Vaše príjmy zo živnosti (faktúry), 
      ktoré Vám boli vystavené v roku ${taxYear} okrem autorskych odmien (za licenciu a vytvorenie diela), 
      ale vyplatené Vám boli až v roku ${taxYear + 1}. Tieto faktúry nebudú zahrnuté do príjmov, ale len do 
      pohľadáviek na konci roka.`}
    />
  ),
  zdravotnePoistenie: () => (
    <Odvody
      dataId={data.id}
      disabled={viewOnly}
      subtitle="Zdravotne poistenie"
      rows={data.zdravotnePoistenie ?? []}
      title="Zdravotné poistenie"
      handleDataUpdate={(rows): void => {
        handleDataUpdate({
          field: 'zdravotnePoistenie',
          value: rows,
          forceSave: false,
        })
      }}
    />
  ),
  socialnePoistenie: () => (
    <Odvody
      dataId={data.id}
      disabled={viewOnly}
      subtitle="Sociálne poistenie"
      title="Sociálne poistenie (okrem dobrovoľného sociálneho poistenia)"
      rows={data.socialnePoistenie ?? []}
      handleDataUpdate={(rows): void => {
        handleDataUpdate({
          field: 'socialnePoistenie',
          value: rows,
          forceSave: false,
        })
      }}
    />
  ),
  inePrimy: () => <InePrijmy key="inePrijmy" data={data} handleDataUpdate={handleDataUpdate} />,
  inePrijmyCelkovyZisk: () => (
    <InePrijmyCelkovyZisk
      dataId={data.id}
      disabled={viewOnly}
      title="Podiel vo firme v nezmluvnom štáte"
      subtitle=""
      rows={data.inePrijmyCelkovyZisk ?? []}
      handleDataUpdate={(rows) => {
        handleDataUpdate({
          field: 'inePrijmyCelkovyZisk',
          value: rows,
          forceSave: false,
        })
      }}
    />
  ),
  rodneCisloManzelaManzelky: () => (
    <BirthNumberFields
      beforeSlashDataKey="rodneCisloPredLomitkomManzelky"
      afterSlashDataKey="rodneCisloZaLomitkomManzelky"
      data={data}
      handleDataUpdate={handleDataUpdate}
      title="Rodné číslo manžela/manželky"
      disabled={viewOnly}
    />
  ),
})

export type CustomFields = ReturnType<typeof getCustomFields>

export const getSlovakiaFlowDescription = (taxYear: number): FlowDescription<SkStep> => ({
  stepsOrder: [
    'basic-information',
    'lowering-base-of-tax',
    'incomes-and-expenses',
    'other-incomes',
    'summary',
    '2-percent',
  ],
  steps: {
    'basic-information': {
      title: 'Základné informácie',
      sections: [
        {
          fields: [
            {
              label: 'DIČ',
              dataKey: 'dic',
              type: 'text', // TODO: Need to be long number string
            },
            {
              label: 'SK NACE',
              dataKey: 'skNACE',
              type: 'text',
              helperText: (
                <>
                  See a{' '}
                  <a key={'nace-link'} href="http://www.nace.sk/" target="_blank" rel="noreferrer">
                    list
                  </a>{' '}
                  of valid SK NACE
                </>
              ),
            },
          ],
        },
        {
          title: 'Údaje o daňovníkovi',
          fields: [
            {
              label: 'Priezvisko',
              dataKey: 'lastName',
              type: 'text',
            },
            {
              label: 'Meno',
              dataKey: 'firstName',
              type: 'text',
            },
            {
              label: 'Titul pred menom',
              dataKey: 'titleBefore',
              type: 'text',
            },
            {
              label: 'Titul za menom',
              dataKey: 'titleAfter',
              type: 'text',
            },
          ],
        },
        {
          title: 'Adresa trvalého pobytu',
          fields: [
            {
              label: 'Ulica',
              dataKey: 'trvalyPobytUlica',
              type: 'text',
            },
            {
              label: 'Súpisné číslo',
              dataKey: 'trvalyPobytSupisneCislo',
              type: 'text',
            },
            {
              label: 'Orientačné číslo',
              dataKey: 'trvalyPobytOrientacneCislo',
              type: 'text',
            },
            {
              label: 'PSČ',
              dataKey: 'trvalyPobytPSC',
              type: 'text',
            },
            {
              label: 'Obec',
              dataKey: 'trvalyPobytObec',
              type: 'text',
            },
            {
              label: 'Štát',
              dataKey: 'trvalyPobytStat',
              type: 'text',
            },
          ],
        },
        {
          title: 'Kontakt',
          fields: [
            {
              label: 'Telefónne číslo',
              dataKey: 'kontaktTelCislo',
              type: 'text',
            },
            {
              label: 'Emailová adresa',
              dataKey: 'kontaktEmail',
              type: 'text',
            },
            {
              label: 'IBAN',
              dataKey: 'kontaktIBAN',
              type: 'text',
            },
          ],
        },
      ],
    },
    'lowering-base-of-tax': {
      title: 'Zníženie základu dane',
      sections: [
        {
          title: 'Poberali ste na začiatku zdaňovacieho obdobia dôchodok(ky)?',
          dataKey: 'poberaliSteNaZaciatkuZdanovaciehoObdobiaDochodok',
          optional: true,
          layout: 'column',
          fields: [
            {
              title: 'Úhrnná suma dôchodku(ov) za obdobie v eurách',
              label: 'EUR',
              dataKey: 'uhrnnaSumaDochodkuZaObdobieVEUR',
              type: 'text',
            },
          ],
        },
        {
          title: 'Uplatňujete si daňový bonus na manžela/manželku?',
          dataKey: 'uplatnujemSiDanovyBonusNaManzelku',
          optional: true,
          layout: 'column',
          fields: [
            {
              title: 'Priezvisko a meno manžela/manželky',
              label: 'Priezvisko a meno',
              dataKey: 'priezviskoManzelky',
              type: 'text',
            },
            {
              customField: true,
              type: 'rodneCisloManzelaManzelky',
            },
            {
              title: 'Hrubý príjem v eurách mínus zaplatené poistné',
              label: 'EUR',
              dataKey: 'hrubyPrijemVEURMinusZaplatenePoistneManzelky',
              type: 'text',
            },
            {
              title: 'Počet mesiacov',
              label: 'Mesiace',
              dataKey: 'hrubyPrijemVEURMinusZaplatenePoistnePocetMesiacovManzelky',
              type: 'text',
              description:
                'Počet kalendárnych mesiacov v zdaňovacom období, počas ktorých manžel/manželka splnil(a) podmienky, započítava sa každý kalendárny mesiac, na začiatku ktorého boli splnené.',
            },
          ],
        },
        {
          title: 'Uplatňujete si daňový bonus na zaplatené úroky?',
          dataKey: 'uplatnujemSiDanovyBonusNaZaplateneUroky',
          description: (
            <>
              Daňovým bonusom na zaplatené úroky je suma vo výške 50 % zo zaplatených úrokov v príslušnom zdaňovacom
              období, najviac však do výšky 400 eur za rok.{' '}
              <a
                key="link-finSprava2"
                href="https://podpora.financnasprava.sk/797363-Podmienky-na-uplatnenie-da%C5%88ov%C3%A9ho-bonusu-na-zaplaten%C3%A9-%C3%BAroky"
                target="_blank"
                rel="noreferrer"
              >
                Viac info tu.
              </a>
            </>
          ),
          optional: true,
          layout: 'column',
          fields: [
            {
              title: 'Zaplatené úroky za zdaňovacie obdobie v eurách',
              label: 'EUR',
              dataKey: 'zaplateneUrokyZaZdanovacieObdobieVEUR',
              type: 'text',
            },
            {
              title: 'Počet mesiacov',
              label: 'Mesiace',
              dataKey: 'zaplateneUrokyZaZdanovacieObdobieVEURPocetMesiacov',
              type: 'text',
            },
            {
              title: 'Dátum začatia úročenia úveru',
              label: 'Dátum',
              dataKey: 'datumZacatiaUroceniaUveru',
              type: 'date',
            },
            {
              title: 'Potvrdenie o zaplatených úrokoch od banky',
              dataKey: 'potrvrdenieOZaplatenychUrokochOdBanky',
              label: 'Potvrdenie',
              type: 'file',
            },
          ],
        },
        {
          title: 'Máte preukázateľne zaplatené príspevky na doplnkové dôchodkové sporenie?',
          dataKey: 'matePreukazatelneZaplatenePrispevkyNaDoplnkoveDochodkoveSporenie',
          description: (
            <>
              Základ dane znižuje o max. 180 Eur.{' '}
              <a
                key="link-finSprava"
                href="https://podpora.financnasprava.sk/609555-Zn%C3%AD%C5%BEenie-z%C3%A1kladu-dane-o-pr%C3%ADspevky-na-doplnkov%C3%A9-d%C3%B4chodkov%C3%A9-sporenie-III-pilier"
                target="_blank"
                rel="noreferrer"
              >
                Viac info tu.
              </a>
            </>
          ),
          optional: true,
          layout: 'column',
          fields: [
            {
              title: 'Zaplatené príspevky za zdaňovacie obdobie v eurách',
              label: 'EUR',
              dataKey: 'danovyBonusDDSVEUR',
              type: 'text',
            },
            {
              title: 'Potvrdenie o príspevkoch na DPP',
              dataKey: 'prispevkyNaDDP',
              label: 'Potvrdenie',
              type: 'file',
            },
          ],
        },
        {
          title:
            'Spĺňam podmienky pre mikrodaňovníka podľa § 2 písm. w) zákona pri príjmoch podľa § 6 ods. 1 a 2 zákona',
          dataKey: 'splnamPodmienkyNaMikrodanovnika',
          description: (
            <>
              <a
                href="https://www.podnikajte.sk/dan-z-prijmov/mikrodanovnik-vyhody-2021"
                target="_blank"
                rel="noreferrer"
              >
                Viac info tu.
              </a>
            </>
          ),
          optional: true,
          layout: 'column',
          fields: [],
        },
        {
          title: 'Uplatňujete si daňový bonus na dieťa/deti?',
          dataKey: 'uplatnujeteSiDanovyBonusNaDieta',
          optional: true,
          layout: 'column',
          fields: [
            {
              customField: true,
              type: 'danovyBonusNaDeti',
            },
            {
              title: 'Existuje druhá oprávnená osoba na uplatnenie daňového bonusu na vyživované dieťa/deti?',
              dataKey: 'danovyBonusNaDetiInaOsoba',
              description: (
                <>
                  Uplatňujem postup podľa §33 ods. 8 zákona t.j. nedosahujem dostatočný základ dane z príjmov podľa §5
                  alebo §6 ods. 1 a 2 zákona alebo ich úhrnu, na uplatnenie celého nároku daňového bonusu a existuje
                  druhá oprávnená osoba na uplatnenie daňového bonusu na vyživované dieťa (deti).
                </>
              ),
              optional: true,
              layout: 'column',
              fields: [
                {
                  title: 'Priezvisko a meno druhej oprávnenej osoby',
                  label: 'Priezvisko a meno',
                  dataKey: 'danovyBonusNaDetiInaOsobaMenoPriezvisko',
                  type: 'text',
                },
                {
                  customField: true,
                  type: 'danovyBonusNaDetiInaOsobaRodneCislo',
                },
                {
                  title:
                    'Mesiace, na začiatku ktorých druhá oprávnená osoba splnila podmienky na uplatnenie daňového bonusu',
                },
                {
                  customField: true,
                  type: 'danovyBonusNaDetiInaOsobaMesiace',
                },
                {
                  customField: true,
                  type: 'danovyBonusNaDetiInaOsobaDoklady',
                },
                {
                  title: 'Základ dane druhej oprávnenej osoby v eurách',
                  description:
                    'Základ dane (čiastkový základ dane) z príjmov zo závislej činnosti alebo z príjmov z podnikania alebo z inej samostatne zárobkovej činnosti alebo ich úhrnu druhej orpávnenej osoby, pre uplatnenie daňového bonusu',
                  label: 'EUR',
                  dataKey: 'danovyBonusNaDetiInaOsobaZakladDane',
                  type: 'text',
                },
                {
                  title: 'Doklad preukazujúci oprávnenosť nároku na priznanie daňového bonusu druhou oprávnenou osobou',
                  description:
                    'Povinnosť priložiť len v prípade, ak rodný list dieťaťa nebol vydaný na územi SR a zároveň ešte nebol predložený daňovému úradu v minulosti. Napríklad: Rodný list dieťaťa, sobášny list a rodný list, rozhodnutie príslušného orgánu, ak ide o dieťa prevzaté do starostlivosti.',
                  label: 'Doklad',
                  dataKey: 'danovyBonusNaDetiInaOsobaRodnyListEtcAttachment',
                  type: 'file',
                },
              ],
            },
          ],
        },
      ],
    },
    'incomes-and-expenses': {
      title: 'Príjmy a odvody',
      sections: [
        {
          title: 'Mali ste príjem zo závislej činnosti (trvalý pracovný pomer, dohoda; NIE živnosť)?',
          optional: true,
          dataKey: 'maliStePrijemZoZavislejCinnosti',
          description: (
            <>
              Na vyplnenie údajov budete potrebovať potvrdenie o zdaniteľných príjmoch fyzickej osoby zo závislej
              činnosti, ktoré má zamestnávateľ povinnosť vystaviť.{' '}
              <a
                key={'link-financnaSprava'}
                href="https://www.financnasprava.sk/_img/pfsedit/Dokumenty_PFS/Zverejnovanie_dok/Vzory_tlaciv/Zavisla_cinnost_5ZD/2020/2020.12.07_Potvrdenie.rtf"
                target="_blank"
                rel="noreferrer"
              >
                Príklad potvrdenia.
              </a>
            </>
          ),
          layout: 'column',
          fields: [
            {
              title: 'Úhrn príjmov od všetkých zamestnávateľov',
              label: 'EUR',
              type: 'text',
              dataKey: 'uhrnPrijmovOdVsetkychZamestnavatelov',
              description: (
                <>
                  Vyplňte súčet hodnôt od všetkých zamestnávateľov. Hodnoty sa nachádzajú v tabuľke Údaje o zdaniteľných
                  príjmoch a ďalšie údaje, riadok 01.
                </>
              ),
            },
            {
              title: 'Z toho úhrn príjmov plynúcich na základe dohôd o prácach vykonávaných mimo pracovného pomeru',
              label: 'EUR',
              type: 'text',
              dataKey: 'uhrnPrijmovMimoPracovnehoPomeru',
              description: (
                <>
                  Vyplňte súčet hodnôt od všetkých zamestnávateľov. Hodnoty sa nachádzajú v tabuľke Údaje o zdaniteľných
                  príjmoch a ďalšIe údaje, riadok 01a.
                </>
              ),
            },
            {
              title: 'Úhrn povinného sociálného poistného 5 ods. 8 zákona',
              label: 'EUR',
              type: 'text',
              dataKey: 'uhrnSocialnehoPoistnehoZTPP',
              description: (
                <>
                  Vyplňte súčet hodnôt od všetkých zamestnávateľov. Hodnoty sa nachádzajú v tabuľke Údaje o zdaniteľných
                  príjmoch a ďalšIe údaje, riadok 02a.
                </>
              ),
            },
            {
              title: 'Úhrn povinného zdravotného poistného 5 ods. 8 zákona',
              label: 'EUR',
              type: 'text',
              dataKey: 'uhrnZdravotnehoPoistnehoZTPP',
              description: (
                <>
                  Vyplňte súčet hodnôt od všetkých zamestnávateľov. Hodnoty sa nachádzajú v tabuľke Údaje o zdaniteľných
                  príjmoch a ďalšIe údaje, riadok 02b.
                </>
              ),
            },
            {
              title: 'Úhrn preddavkov na daň zo závislej činnosti',
              label: 'EUR',
              type: 'text',
              dataKey: 'sumaPreddavkovTPP',
              description: (
                <>
                  Vyplňte súčet hodnôt od všetkých zamestnávateľov. Hodnoty sa nachádzajú v tabuľke Údaje o zdaniteľných
                  príjmoch a ďalšIe údaje, riadok 04.
                </>
              ),
            },
            {
              title: 'Príjem zo závislej činnosti',
              label: 'Príloha',
              type: 'file',
              dataKey: 'prijemZoZavislejCinnostiAttachment',
            },
          ],
        },
        {
          title: 'Mali ste príjem zo závislej činnosti v zahraničí (pracovný pomer, dohoda)?',
          optional: true,
          dataKey: 'maliStePrijemZoZavislejCinnostiZahranicie',
          layout: 'column',
          fields: [
            {
              title: 'Úhrn príjmov od všetkých zamestnávateľov',
              label: 'EUR',
              type: 'text',
              dataKey: 'uhrnPrijmovOdVsetkychZamestnavatelovZahranicie',
            },
            {
              title: 'Z toho úhrn príjmov plynúcich na základe dohôd o prácach vykonávaných mimo pracovného pomeru',
              label: 'EUR',
              type: 'text',
              dataKey: 'uhrnPrijmovMimoPracovnehoPomeruZahranicie',
            },
            {
              title: 'Úhrn povinného poistného 5 ods. 8 zákona',
              label: 'EUR',
              type: 'text',
              dataKey: 'uhrnPoistnehoZTPPZahranicie',
            },
            {
              title: 'Príjem zo závislej činnosti v zahraničí',
              label: 'Príloha',
              type: 'file',
              dataKey: 'prijemZoZavislejCinnostiAttachmentZahranicie',
            },
          ],
        },
        {
          title: 'Príjmy a odvody zo živnosti, vytvorenie diela a z použitia diela (SZČO)',
          titleType: 'headline',
          fields: [],
        },
        {
          title: `Platili ste v roku ${taxYear} preddavky na daň ako živnostník?`,
          optional: true,
          dataKey: 'platiliStePreddavkyNaDanAkoZivnostnik',
          layout: 'column',
          description: (
            <>
              Týka sa zaplatených preddavkov týkajúcich sa zdaniteľného obdobia, za ktore sa podáva DP - {taxYear - 1}.
            </>
          ),
          fields: [
            {
              title: 'Suma preddavkov',
              label: 'EUR',
              type: 'text',
              dataKey: 'sumaPreddavkov',
            },
          ],
        },
        {
          title: 'AUTORSKÝ PRÍJEM (väčšinou developeri)',
          fields: [
            {
              customField: true,
              type: 'autorskyPrijemRowsPohladavkyZMinulehoRoku',
            },
            {
              customField: true,
              type: 'autorskyPrijemRows',
            },
            {
              customField: true,
              type: 'autorskyPrijemRowsPohladavky',
            },
          ],
        },
        {
          title: 'OBYČAJNÝ PRÍJEM (neautorský)',
          fields: [
            {
              customField: true,
              type: 'obycajnyPrijemRowsPohladavkyZMinulehoRoku',
            },
            {
              customField: true,
              type: 'obycajnyPrijemRows',
            },
            {
              customField: true,
              type: 'obycajnyPrijemRowsPohladavky',
            },
          ],
        },
        {
          layout: 'column',
          fields: [
            {
              customField: true,
              type: 'zdravotnePoistenie',
            },
            {
              title: 'Zaplatené nedoplatky na zdravotnom poistení',
              type: 'text',
              dataKey: 'zaplateneNedoplatkyZdravotnePoistenie',
              label: 'EUR',
            },
          ],
        },
        {
          layout: 'column',
          fields: [
            {
              customField: true,
              type: 'socialnePoistenie',
            },
            {
              title: 'Zaplatené nedoplatky na sociálnom poistení',
              type: 'text',
              dataKey: 'zaplateneNedoplatkySocialnePoistenie',
              label: 'EUR',
            },
            {
              title: 'Zaplatené dobrovoľné sociálne poistenie',
              type: 'text',
              dataKey: 'zaplateneDobrovolneSocialnePoistenie',
              label: 'EUR',
            },
          ],
        },
      ],
    },
    '2-percent': {
      title: 'Darovanie 2%',
      sections: [
        {
          title: 'Chcem poukázať 2% zo zaplatenej dane z príjmov ?',
          description: 'V prípade, že je zakliknuté a nevyplnené, Vacuumlabs vyberie za teba.',
          dataKey: 'chcemPoukazatDvePercenta',
          optional: true,
          layout: 'column',
          fields: [
            {
              title: 'Údaje o prijímateľovi',
            },
            {
              dataKey: 'prijmatelICO',
              type: 'text',
              label: 'IČO',
            },
            {
              dataKey: 'prijmatelObchodneMeno',
              type: 'text',
              label: 'Obchodné meno',
            },
            {
              title:
                'Spĺňam podmienky na poukázanie 3% z dane (mám potvrdenie o odpracovaní min 40 dobrovoľníckych hodín od hocijakej neziskovky)?',
              type: 'checkbox',
              dataKey: 'splnamPodmienkyNaTriPercenta',
            },
            {
              title: 'Potvrdenie o odpracovaní min 40 dobrovoľníckych hodín od neziskovky pre poukázanie 3% z dane',
              dataKey: 'splnamPodmienkyNaTriPercentaAttachment',
              label: 'Potvrdenie',
              type: 'file',
            },
            {
              title:
                'Súhlasím so zaslaním údajov (meno, priezvisko a adresa trvalého pobytu) prijímateľovi podielu zaplatenej dane?',
              type: 'checkbox',
              dataKey: 'suhlasimSoZaslanimUdajovPrijmatelovi',
            },
          ],
        },
      ],
    },
    'other-incomes': {
      title: 'Iné prijmy',
      sections: [
        {
          title: 'Iné prijmy',
          layout: 'column',
          fields: [{type: 'inePrimy', customField: true}],
        },
        {
          title:
            'Mám firmu s min. 10% podielom v nezmluvnom štáte alebo v štáte so zdanením menej ako 10% bez personálneho a priestorového vybavenia a môj úhrnný ZISK zo všetkých firiem je nad 100 000 €',
          dataKey: 'mamFirmuSMinPodielom',
          optional: true,
          layout: 'column',
          fields: [
            {
              customField: true,
              type: 'inePrijmyCelkovyZisk',
            },
          ],
        },
      ],
    },
    summary: {
      title: 'Súhrn',
      sections: [],
    },
  },
})
