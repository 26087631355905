import {AddCircleRounded, ContentCopy, Delete, Edit} from '@mui/icons-material'
import {type IconButtonProps, IconButton, Stack, Toolbar, Tooltip, Typography} from '@mui/material'
import type {ReactNode} from 'react'
import React from 'react'

type IconButtonWithTooltipProps = {
  title: string
  onClick: () => void
  disabled?: boolean
  children?: ReactNode
} & IconButtonProps

const IconButtonWithTooltip = ({title, onClick, disabled, children, ...iconButtonProps}: IconButtonWithTooltipProps) =>
  disabled ? (
    <IconButton disabled {...iconButtonProps}>
      {children}
    </IconButton>
  ) : (
    // MUI cannot use tooltips with disabled buttons
    // Also we don't want to just wrap the button in <span> as that gives aria props to the span, not button
    <Tooltip title={title}>
      <IconButton onClick={onClick} {...iconButtonProps}>
        {children}
      </IconButton>
    </Tooltip>
  )

type TableHeaderProps = {
  title: string
  subtitle: string
  selectedRowsCount: number
  handlers: {handleAdd: () => void; handleEdit: () => void; handleDelete: () => void; handleDuplicate: () => void}
  disabled?: boolean
}

export const TableHeader = ({handlers, title, subtitle, selectedRowsCount, disabled}: TableHeaderProps) => {
  const {handleAdd, handleEdit, handleDelete, handleDuplicate} = handlers

  return (
    <Stack sx={{px: 2, py: 1}}>
      <Stack alignItems="center" gap={2} flexShrink={0} direction="row" justifyContent="space-between">
        <Typography variant="h6" component="div">
          {title}
        </Typography>

        {!disabled && (
          <Toolbar variant="dense" disableGutters>
            <IconButtonWithTooltip title="Upraviť" disabled={selectedRowsCount !== 1} onClick={handleEdit}>
              <Edit />
            </IconButtonWithTooltip>

            <IconButtonWithTooltip title="Duplikovať" disabled={selectedRowsCount !== 1} onClick={handleDuplicate}>
              <ContentCopy />
            </IconButtonWithTooltip>

            <IconButtonWithTooltip
              title="Vymazať"
              color="error"
              disabled={selectedRowsCount < 1}
              onClick={handleDelete}
            >
              <Delete />
            </IconButtonWithTooltip>

            <IconButtonWithTooltip title="Pridať" color="primary" onClick={handleAdd}>
              <AddCircleRounded />
            </IconButtonWithTooltip>
          </Toolbar>
        )}
      </Stack>

      {subtitle !== '' && (
        <Typography sx={{mt: 1, mb: 2}} variant="body2" component="p">
          {subtitle}
        </Typography>
      )}
    </Stack>
  )
}
