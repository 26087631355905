import {TableCell, TableHead as MuiTableHead, TableRow} from '@mui/material'
import React from 'react'

import type {ColumnType, RowType} from './Table'

type TableHeadProps<Row extends RowType> = {
  columns: Array<ColumnType<Row>>
}

export const TableHead = <Row extends RowType>({columns}: TableHeadProps<Row>) => {
  return (
    <MuiTableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell key={column.property} align={column.inputType === 'number' ? 'right' : 'left'} variant="head">
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  )
}
