import {Checkbox, Paper, Stack, Switch, TextField, Typography} from '@mui/material'
import React, {type ReactNode} from 'react'
import type {Data, DataItem} from 'types'

import {uploadToStorage} from '../../helpers/firebaseHelpers'
import type {CustomFields} from '../slovakiaFlow/slovakiaFlowConfiguration'
import {SingleFileUpload} from './SingleFileUpload'
import type {CustomField, FormField, InfoField, Section} from './types'

type SectionWrapperProps = {
  title?: string
  children: ReactNode
  toggle?: ReactNode
  description?: ReactNode
  headline?: boolean
}

const SectionWrapper = ({title, children, toggle, description, headline}: SectionWrapperProps) => {
  const isTitleSectionVisible = (title !== undefined && title !== '') || toggle !== undefined
  const isChildrenVisible = children !== null && Boolean(children)
  const isDescriptionVisible = description !== undefined

  return (
    <Stack
      {...(headline
        ? {}
        : {
            boxShadow: 'rgb(145 158 171 / 48%) 0px 0px 1px 0px, rgb(145 158 171 / 24%) 0px 2px 4px -1px',
            bgcolor: 'white',
          })}
      flexGrow={0}
      borderRadius={1}
      alignSelf="stretch"
    >
      {isTitleSectionVisible && (
        <Stack borderBottom={isChildrenVisible ? 1 : 0} borderColor="divider">
          <Stack direction="row" justifyContent="space-between" alignItems="center" paddingX={2} paddingY={1}>
            {title !== undefined && title !== '' && (
              <Typography data-testid="typography" variant={headline ? 'h5' : 'body1'} component="p" fontWeight="500">
                {title}
              </Typography>
            )}
            {toggle}
          </Stack>
          {isDescriptionVisible && (
            <Typography paddingX={2} paddingTop={0} paddingBottom={2} variant="body2" mt={-1}>
              {description}
            </Typography>
          )}
        </Stack>
      )}
      {isChildrenVisible && (
        <Stack direction="column" columnGap={2} padding={2}>
          {children}
        </Stack>
      )}
    </Stack>
  )
}

type FormFieldComponentProps = {
  field: FormField
  data: Data
  handleDataUpdate: (dataItem: DataItem) => void
  viewOnly: boolean
}

const FormFieldComponent = ({
  field: {dataKey, label, type, description, title},
  data,
  handleDataUpdate,
  viewOnly,
}: FormFieldComponentProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      flexWrap={description ? 'nowrap' : 'wrap'}
    >
      {title && (
        <Stack>
          <Typography key="typography3" data-testid="typography-pension" variant="body1">
            {title}
          </Typography>
          {description && (
            <Typography mr={2} variant="caption">
              {description}
            </Typography>
          )}
        </Stack>
      )}
      <Stack direction="row" spacing={2}>
        {type === 'file' && (
          <SingleFileUpload
            label={label ?? 'Príloha'}
            existingAttachment={data[dataKey] !== undefined ? String(data[dataKey]) : null}
            disabled={viewOnly}
            fileChangeFunc={async (file) => {
              await uploadToStorage(file, dataKey, data.id).then((downloadURL) => {
                handleDataUpdate({
                  field: dataKey,
                  value: downloadURL,
                  forceSave: true,
                })
              })
            }}
            fileId={dataKey}
          />
        )}
        {(type === 'text' || type === 'email' || type === 'date') && (
          <TextField
            disabled={viewOnly}
            label={label}
            type={type}
            size="small"
            sx={{width: '230px'}}
            onWheel={(e): void => (e.target as HTMLDivElement).blur()}
            value={data[dataKey] ?? ''}
            onChange={(event) => {
              handleDataUpdate({
                field: dataKey,
                value: event.target.value,
              })
            }}
            InputLabelProps={
              type === 'date'
                ? {
                    shrink: true, // prevent date hint 'mm/dd/yyyy' to be overlapped by label
                  }
                : {}
            }
          />
        )}
        {type === 'checkbox' && (
          <Checkbox
            checked={Boolean(data[dataKey])}
            disabled={viewOnly}
            onChange={(event) => {
              handleDataUpdate({
                field: dataKey,
                value: event.target.checked,
              })
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

type CustomFieldComponentProps = {
  field: CustomField
  customFields: CustomFields | undefined
}

const CustomFieldComponent = ({field: {type}, customFields}: CustomFieldComponentProps) => {
  const customType = type as keyof CustomFields
  return !customFields || !customFields[customType] ? null : <>{customFields[customType]()}</>
}

type InfoFieldComponentProps = {
  field: InfoField
}

const InfoFieldComponent = ({field: {title, description}}: InfoFieldComponentProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      flexWrap={description ? 'nowrap' : 'wrap'}
    >
      {title && (
        <Stack>
          <Typography key="typography3" data-testid="typography-pension" variant="body1">
            {title}
          </Typography>
          {description && (
            <Typography mr={2} variant="caption">
              {description}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  )
}

type SectionComponentProps = {
  // TODO: data needs to be removed from section props!!!!
  data: Data
  handleDataUpdate: (dataItem: DataItem) => void
  section: Section
  viewOnly: boolean
  customFields?: CustomFields | undefined
}

export const SectionComponent = ({data, section, handleDataUpdate, viewOnly, customFields}: SectionComponentProps) => {
  const {dataKey: sectionDataKey} = section

  const hasFields = section.fields.length > 0

  const ToggleSectionComponent = hasFields ? Switch : Checkbox

  const renderToggle = section.optional && sectionDataKey && (
    <ToggleSectionComponent
      checked={Boolean(data[sectionDataKey]) ?? false}
      disabled={viewOnly}
      onChange={(event): void => {
        handleDataUpdate({
          field: sectionDataKey,
          value: event.target.checked,
        })
      }}
    />
  )

  const showFields = hasFields && (!section.optional || (section.dataKey && Boolean(data[section.dataKey])))

  return (
    <SectionWrapper
      title={section.title ?? ''}
      headline={section.titleType === 'headline'}
      description={section.description}
      toggle={renderToggle}
    >
      {showFields && (
        <Stack flex={1} rowGap={2} columnGap={2} direction={section.layout ?? 'row'} flexWrap="wrap">
          {section.fields.map((field, i) => {
            if ((field as Section).fields) {
              return (
                <Paper variant="outlined" key={`subsection-${field.dataKey ?? i}`}>
                  <SectionComponent
                    data={data}
                    section={field as Section}
                    handleDataUpdate={handleDataUpdate}
                    viewOnly={viewOnly}
                    customFields={customFields}
                  />
                </Paper>
              )
            }
            if ((field as FormField).dataKey) {
              return (
                <FormFieldComponent
                  field={field as FormField}
                  key={field.dataKey}
                  data={data}
                  handleDataUpdate={handleDataUpdate}
                  viewOnly={viewOnly}
                />
              )
            }
            if ((field as InfoField).title) {
              return <InfoFieldComponent field={field as InfoField} key={`field-${i}`} />
            }
            if ((field as CustomField).customField) {
              return <CustomFieldComponent field={field as CustomField} key={field.type} customFields={customFields} />
            }
          })}
        </Stack>
      )}
    </SectionWrapper>
  )
}
