import {Box} from '@mui/material'
import {DataGrid} from '@mui/x-data-grid'
import React, {useState} from 'react'

import {rowsPerPageConstant, rowsPerPageOptionsConstant} from '../../constants'

type Row = {
  id: string
  email: string
  role: string
}

type MembershipTableProps = {
  rows: Row[]
}

const columns = [
  {field: 'id', headerName: 'ID', width: 50},
  {field: 'email', headerName: 'Email', width: 300},
  {field: 'role', headerName: 'Membership', width: 200},
]

export const MembershipTable = ({rows}: MembershipTableProps) => {
  const [pageSize, setPageSize] = useState(rowsPerPageConstant)
  return (
    <Box
      boxShadow="rgb(145 158 171 / 48%) 0px 0px 1px 0px, rgb(145 158 171 / 24%) 0px 2px 4px -1px"
      padding={2}
      bgcolor="white"
      borderRadius={1}
      data-testid="membership-table-wrapper"
      style={{height: '80vh', width: '100%'}}
    >
      <DataGrid
        rowBuffer={rows.length}
        columnBuffer={columns.length + 1}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={rowsPerPageOptionsConstant}
        disableSelectionOnClick
      />
    </Box>
  )
}
