import {Stack, ToggleButton, ToggleButtonGroup} from '@mui/material'
import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {AdminWrap} from '../components/admin/AdminWrap'
import {AdminStatistics} from '../components/finance/AdminStatistics'
import {Reconciliations} from '../components/finance/Reconciliations'
import {StatisticForStatus} from '../components/finance/StatisticForStatus'
import {loadAllReconciliations} from '../helpers/firebaseHelpers'
import {useLoading} from '../helpers/loading'
import type {ApprovalStatus, Data} from '../types'
import {useTaxYear} from '../utils'

export type Stats = Record<string, Record<ApprovalStatus, number>>

type FinanceProps = {
  isAdmin: boolean
}

export const Finance = ({isAdmin}: FinanceProps) => {
  const taxYear = useTaxYear()
  const {setLoading} = useLoading()

  const [data, setData] = useState<Data[]>([])

  const [showArchived, setShowArchived] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [showStatistics, setShowStatistics] = useState(false)

  const statistics = useMemo(
    () =>
      data.reduce((acc: Stats, reconciliation: Data) => {
        const approvalStatus1 = reconciliation.approvalStatus1

        if (approvalStatus1 !== undefined) {
          const approverStatistic = acc[approvalStatus1] ?? {COMPLETED: 0, IN_PROGRESS: 0, REJECTED: 0}

          const approvalStatus1status = reconciliation.approvalStatus1status
          if (approvalStatus1status !== undefined) {
            approverStatistic[approvalStatus1status] += 1
          }

          acc[approvalStatus1] = approverStatistic
        }

        return acc
      }, {}),
    [data],
  )

  const counters = useMemo(
    () =>
      data.reduce((acc: Record<string, number>, {status}: Data) => {
        if (status !== undefined) {
          if (acc[status] !== undefined) {
            acc[status] += 1
          } else {
            acc[status] = 1
          }
        }
        return acc
      }, {}),
    [data],
  )

  const loadCountersAndStats = useCallback(() => {
    setLoading(true)
    loadAllReconciliations(taxYear, showAll)
      .then((newData) => {
        if (showArchived) {
          // Show both archived and non-archived
          setData(newData)
        } else {
          // Show only non-archived
          setData(newData.filter((r: Data) => (r.isArchived ?? false) === false))
        }
      })
      .catch((e) => {
        alert(e)
      })
      .finally(() => setLoading(false))
  }, [taxYear, showAll, showArchived, setLoading])

  useEffect(() => {
    loadCountersAndStats()
  }, [loadCountersAndStats])

  const toggleButtons = (
    <Stack direction="row" spacing={2} alignItems="center">
      {isAdmin && (
        <ToggleButton
          size="small"
          sx={{paddingX: 3}}
          color="primary"
          value="show-stats"
          selected={showStatistics}
          onChange={() => setShowStatistics(!showStatistics)}
        >
          Approval statistics
        </ToggleButton>
      )}
      <ToggleButtonGroup
        value={showAll}
        onChange={(_, newShowAll: boolean | null) => newShowAll != null && setShowAll(newShowAll)}
        exclusive
      >
        <ToggleButton size="small" sx={{paddingX: 3}} color="primary" value={false}>
          Show submitted+
        </ToggleButton>
        <ToggleButton size="small" sx={{paddingX: 3}} color="primary" value={true}>
          Show all
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )

  const statisticCards = (
    <Stack direction="row" spacing={2}>
      {Object.entries(counters).map(([status, count]) => (
        <StatisticForStatus count={count} label={status.replace('_', ' ')} key={status} />
      ))}
    </Stack>
  )

  return (
    <Stack spacing={2} justifyContent="center" p={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {statisticCards}
        {toggleButtons}
      </Stack>
      {showStatistics && <AdminStatistics statistics={statistics} />}
      <AdminWrap>
        <Reconciliations
          isAdmin={isAdmin}
          data={data}
          showAll={showAll}
          handleShowAllChange={setShowAll}
          showArchived={showArchived}
          handleShowArchivedChange={setShowArchived}
        />
      </AdminWrap>
    </Stack>
  )
}
