import {ChevronLeftRounded, ChevronRightRounded} from '@mui/icons-material'
import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Step,
  StepButton,
  Stepper,
  Toolbar,
  Typography,
} from '@mui/material'
import React, {useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {SectionComponent} from '../components/common/FlowComponents'
import {ApprovalFlow} from '../components/finance/ApprovalFlow'
import {getCustomFields, getSlovakiaFlowDescription} from '../components/slovakiaFlow/slovakiaFlowConfiguration'
import {Spolu} from '../components/slovakiaFlow/Spolu'
import {submitReconciliation, updateReconciliation} from '../helpers/firebaseHelpers'
import {useLoading} from '../helpers/loading'
import type {Data, DataItem} from '../types'

type DownloadFile = {
  data: string
  fileName: string
  fileType: string
}

const showFinalSubmitText = 'showFinalSubmitText'

const downloadFile = ({data, fileName, fileType}: DownloadFile): string => {
  try {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], {type: fileType})
    // Create an anchor element and dispatch a click event on it to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvent)
    a.remove()
    return 'success'
  } catch {
    return 'unable to export data'
  }
}

type LastStepProps = {
  data: Data
  handleDataUpdate: (item: DataItem) => void
  viewOnly: boolean
}

const LastStep = ({data, handleDataUpdate, viewOnly}: LastStepProps) => {
  const navigate = useNavigate()
  const {setLoading} = useLoading()

  const isSubmitEnabled = data.status === 'NEW' || data.status === 'IN_PROGRESS'
  const [gdprAccepted, setGdprAccepted] = useState(false)

  const handleSubmitReconcilation = () => {
    setLoading(true)
    submitReconciliation(data, data.id)
      .then((newData) => {
        handleDataUpdate({field: 'status', value: newData.status})
        alert('Reconcilliation submitted')
        navigate('/')
      })
      .catch((e) => {
        alert(e)
      })
      .finally(() => setLoading(false))
  }

  const handleNavigateHome = () => {
    navigate('/')
  }

  return (
    <Stack direction="column" alignItems="center" mt={6}>
      <Typography sx={{mb: 4}}>
        {isSubmitEnabled
          ? 'All steps completed - click on Final Submit to submit your reconciliation.'
          : 'Your reconciliation has already been submitted.'}
      </Typography>

      {isSubmitEnabled && (
        <FormControlLabel
          sx={{mb: 4}}
          control={<Checkbox checked={gdprAccepted} onChange={({target}) => setGdprAccepted(target.checked)} />}
          label={
            <>
              I consent to the collection, and further processing of my data according to{' '}
              <Link
                target="_blank"
                rel="norefferer"
                href="https://firebasestorage.googleapis.com/v0/b/tax-reconciliation-platform.appspot.com/o/public%2FConsent%20to%20the%20processing%20of%20personal%20data%20(1).pdf?alt=media&token=0bb5f692-7255-441b-a8fa-75964dd30a05"
              >
                this document
              </Link>
              .
            </>
          }
        />
      )}

      {isSubmitEnabled ? (
        <Button disabled={viewOnly || !gdprAccepted} variant="contained" onClick={handleSubmitReconcilation}>
          Final Submit
        </Button>
      ) : (
        <Button variant="contained" onClick={handleNavigateHome}>
          Home
        </Button>
      )}
    </Stack>
  )
}

type SlovakiaFlowProps = {
  data: Data
  handleDataUpdate: (item: DataItem) => void
  isFinance: boolean
  isAdmin: boolean
  financeMembers: string[]
  drawerWidth: number | string
  currentTaxYear: number
}

export const SlovakiaFlow = ({
  data,
  handleDataUpdate,
  isFinance,
  isAdmin,
  drawerWidth,
  financeMembers,
  currentTaxYear,
}: SlovakiaFlowProps) => {
  const {setLoading} = useLoading()
  const {viewOnly, taxYear} = data

  const slovakiaFlowDescription = useMemo(() => getSlovakiaFlowDescription(taxYear), [taxYear])

  // TODO: Need to be moved into description of the flow after refactoring.
  const legacyScreens = {
    // 'other-incomes': <InePrijmy key="inePrijmy" data={data} handleDataUpdate={handleDataUpdate} />,
    summary: <Spolu key="spolu" data={data} handleDataUpdate={handleDataUpdate} />,
  }

  const [activeStep, setActiveStep] = useState(0)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [targetStatus, setTargetStatus] = useState('')

  if (activeStep >= slovakiaFlowDescription.stepsOrder.length - 2) {
    window.sessionStorage.setItem(showFinalSubmitText, 'true')
  }

  const [showApprovalFlow, setShowApprovalFlow] = useState(false)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => Math.min(slovakiaFlowDescription.stepsOrder.length, prevActiveStep + 1))
    if (!viewOnly) {
      setLoading(true)
      updateReconciliation(data, data.id)
        .catch((e) => {
          alert(e)
        })
        .finally(() => setLoading(false))
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(0, prevActiveStep - 1))
  }

  const handleConfirmationDialogClose = () => {
    setConfirmDialogOpen(false)
    setTargetStatus('')
  }

  const handleConfirmationDialogConfirm = () => {
    setConfirmDialogOpen(false)
    try {
      handleDataUpdate({
        field: 'status',
        value: targetStatus,
        forceSave: true,
      })
    } catch (e) {
      alert(e)
    }
    setTargetStatus('')
  }

  const isOnLastStep = activeStep === slovakiaFlowDescription.stepsOrder.length

  const renderStepper = (
    <Stack
      padding={1}
      paddingX={2}
      direction="row"
      spacing={4}
      position="sticky"
      top={64}
      zIndex={2}
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: '#f5f5fbcc',
      }}
      boxShadow="rgb(145 158 171 / 48%) 0px 0px 1px 0px, rgb(145 158 171 / 24%) 0px 2px 4px -1px"
    >
      <Button startIcon={<ChevronLeftRounded />} disabled={activeStep === 0} onClick={handleBack} sx={{mr: 1}}>
        Previous
      </Button>
      <Stepper nonLinear activeStep={activeStep} sx={{flex: 1}}>
        {slovakiaFlowDescription.stepsOrder.map((stepName, index) => (
          <Step key={stepName} completed={index < activeStep}>
            <StepButton onClick={() => setActiveStep(index)}>
              {slovakiaFlowDescription.steps[stepName].title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Button endIcon={<ChevronRightRounded />} disabled={isOnLastStep} onClick={handleNext}>
        {activeStep >= slovakiaFlowDescription.stepsOrder.length - 1 ? 'Finish' : 'Next'}
      </Button>
    </Stack>
  )

  const stepName = slovakiaFlowDescription.stepsOrder[activeStep]
  const customFields = useMemo(
    () => getCustomFields(viewOnly, data, handleDataUpdate, taxYear),
    [viewOnly, data, handleDataUpdate, taxYear],
  )

  const renderActiveStep = (
    <Stack spacing={2} paddingY={2}>
      {stepName !== undefined &&
        (stepName in legacyScreens
          ? legacyScreens[stepName as keyof typeof legacyScreens]
          : slovakiaFlowDescription.steps[stepName].sections.map((section, index) => (
              <SectionComponent
                customFields={customFields}
                section={section}
                key={index}
                data={data}
                handleDataUpdate={handleDataUpdate}
                viewOnly={viewOnly}
              />
            )))}
    </Stack>
  )

  const renderFinanceDrawer = (isFinance || isAdmin) && (
    <Drawer anchor="right" open={showApprovalFlow} onClose={() => setShowApprovalFlow(false)}>
      <Stack padding={2}>
        <ApprovalFlow financeMembers={financeMembers} handleDataUpdate={handleDataUpdate} data={data} />
      </Stack>
    </Drawer>
  )

  const renderConfirmDialog = (
    <Dialog open={confirmDialogOpen} onClose={handleConfirmationDialogClose}>
      <DialogTitle>{`Set reconciliation status to ${targetStatus}?`}</DialogTitle>
      <DialogActions sx={{display: 'flex'}}>
        <Button onClick={handleConfirmationDialogClose}>No</Button>
        <Button onClick={handleConfirmationDialogConfirm} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <Stack>
      {renderStepper}

      <Stack paddingX={2} pb={4}>
        {isOnLastStep ? (
          <LastStep data={data} handleDataUpdate={handleDataUpdate} viewOnly={viewOnly} />
        ) : (
          renderActiveStep
        )}
      </Stack>

      {renderFinanceDrawer}

      {renderConfirmDialog}

      <AppBar
        component="div"
        position="fixed"
        color="default"
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: '#f5f5fbcc',
          top: 'auto',
          bottom: 0,
          width: {sm: `calc(100% - ${drawerWidth}px)`},
        }}
        elevation={3}
      >
        <Toolbar>
          <Stack>
            <Typography variant="caption">Daňové priznanie za obdobie {taxYear} pre</Typography>
            <Typography>{data.id}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" ml="auto" spacing={2}>
            <FormControl sx={{minWidth: 200}} size="small">
              <InputLabel id="term-label">Term</InputLabel>
              <Select
                sx={{backgroundColor: '#fff'}}
                size="small"
                label="Term"
                disabled={viewOnly}
                value={data.term ?? ''}
                onChange={(event) => {
                  handleDataUpdate({
                    field: 'term',
                    value: event.target.value,
                    forceSave: true,
                  })
                }}
              >
                <MenuItem value={'STANDARD (MAR31)'}>{'STANDARD (Mar 31st)'}</MenuItem>
                <MenuItem value={'EXTENDED (JUN30)'}>{'EXTENDED (Jun 30th)'}</MenuItem>
              </Select>
            </FormControl>
            <Button
              disabled={viewOnly}
              onClick={() => {
                setLoading(true)
                updateReconciliation(data, data.id)
                  .then(() => {
                    alert('Reconcilliation saved')
                  })
                  .catch((e) => {
                    alert(e)
                  })
                  .finally(() => setLoading(false))
              }}
            >
              Save (draft)
            </Button>
            {Boolean(window.sessionStorage.getItem(showFinalSubmitText)) && (
              <Button
                disabled={viewOnly}
                onClick={() => {
                  setActiveStep(slovakiaFlowDescription.stepsOrder.length)
                }}
              >
                Final Submit
              </Button>
            )}

            {(isFinance || isAdmin) && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  color="secondary"
                  onClick={() => {
                    setShowApprovalFlow(true)
                  }}
                >
                  Approval Process
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    downloadFile({
                      data: JSON.stringify(data),
                      fileName: `${data.id ?? 'export'}.json`,
                      fileType: 'text/json',
                    })
                  }}
                >
                  Export
                </Button>
                <FormControl size="small">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    label="Status"
                    value={data.status ?? ''}
                    size="small"
                    color="secondary"
                    disabled={data.taxYear !== currentTaxYear}
                    // TODO: Redefine background for Select globally
                    sx={{backgroundColor: '#fff'}}
                    onChange={(event) => {
                      if (event.target.value === 'COMPLETED') {
                        setTargetStatus(event.target.value)
                        setConfirmDialogOpen(true)
                      } else {
                        handleDataUpdate({
                          field: 'status',
                          value: event.target.value,
                          forceSave: true,
                        })
                      }
                    }}
                  >
                    {/* TODO: This should be defined outside of the render function. I'm making assumption that all items need to be unique. */}
                    {['NEW', 'IN_PROGRESS', 'SUBMITTED', 'APPROVED', 'GENERATED', 'MANUAL', 'COMPLETED', 'ERROR'].map(
                      (item) => (
                        <MenuItem value={item} key={item}>
                          {item.toLowerCase().replace('_', ' ')}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </Stack>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      {/* NOTE: Added for the bottom spacing to be consistent with the height of the AppBar above. */}
      <Toolbar />
    </Stack>
  )
}
