import {Stack, TextField, Typography} from '@mui/material'
import React from 'react'

import type {Data, DataItem} from '../../types'

type BirthNumberFieldsProps = {
  beforeSlashDataKey: keyof Data
  afterSlashDataKey: keyof Data
  data: Data
  handleDataUpdate: (dataItem: DataItem) => void
  title?: string
  description?: string
  disabled?: boolean
}

export const BirthNumberFields = ({
  beforeSlashDataKey,
  afterSlashDataKey,
  handleDataUpdate,
  title,
  description,
  data,
  disabled,
}: BirthNumberFieldsProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      flexWrap={description ? 'nowrap' : 'wrap'}
    >
      {title && (
        <Stack>
          <Typography key="typography3" data-testid="typography-pension" variant="body1">
            {title}
          </Typography>
          {description && (
            <Typography mr={2} variant="caption">
              {description}
            </Typography>
          )}
        </Stack>
      )}
      <Stack direction="row" alignItems="center">
        <TextField
          disabled={disabled ?? false}
          label="Rodné číslo"
          size="small"
          type="text"
          onWheel={(e) => (e.target as HTMLDivElement).blur()}
          value={data[beforeSlashDataKey] ?? ''}
          onChange={(event) => {
            handleDataUpdate({
              field: beforeSlashDataKey,
              value: event.target.value,
            })
          }}
          inputProps={{maxLength: 6}}
          sx={{width: '120px'}}
        />
        <Typography variant="body1" mx="10px" sx={{width: '10px'}}>
          /
        </Typography>
        <TextField
          disabled={disabled ?? false}
          size="small"
          onWheel={(e) => (e.target as HTMLDivElement).blur()}
          value={data[afterSlashDataKey] ?? ''}
          onChange={(event) => {
            handleDataUpdate({
              field: afterSlashDataKey,
              value: event.target.value,
            })
          }}
          inputProps={{maxLength: 4}}
          sx={{width: '80px'}}
        />
      </Stack>
    </Stack>
  )
}
