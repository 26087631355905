import {useParams} from 'react-router-dom'

import {monthIds, monthLabels} from './constants'
import type {Month, Months} from './types'

export const formatDate = (isoDate: string) => {
  const [year, month, day] = isoDate.split('-')
  return [day, month, year].filter(Boolean).join('. ')
}

export const formatCurrency = (amount: string | number) => `${Number(amount).toFixed(2)} €`

export const formatPeriod = (period: string) => {
  const [paidInMonth, paidInYear, paidForMonth, paidForYear] = period.split('-')
  const paidInMonthIndex = monthIds.indexOf(paidInMonth as Month)
  const paidForMonthIndex = monthIds.indexOf(paidForMonth as Month)
  if (paidInMonthIndex === -1 || paidForMonthIndex === -1) {
    return period
  }

  return `${monthLabels[paidInMonthIndex]} ${paidInYear} - ${monthLabels[paidForMonthIndex]} ${paidForYear}`
}

export const toNumericPeriod = (period: string) => {
  const [paidInMonth, paidInYear, paidForMonth, paidForYear] = period.split('-')
  const paidInMonthIndex = monthIds.indexOf(paidInMonth as Month)
  const paidForMonthIndex = monthIds.indexOf(paidForMonth as Month)
  if (paidInMonthIndex === -1 || paidForMonthIndex === -1) {
    return ''
  }
  return [
    paidInYear,
    (paidInMonthIndex + 1).toString().padStart(2, '0'),
    paidForYear,
    (paidForMonthIndex + 1).toString().padStart(2, '0'),
  ].join('-')
}

export const useTaxYear = () => {
  const {taxYear: taxYearStr} = useParams()
  return Number(taxYearStr)
}

export const generateMonths = (value: boolean): Months =>
  monthIds.reduce((months: Partial<Months>, monthId) => ({...months, [monthId]: value}), {}) as Months
