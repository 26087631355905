import React from 'react'

import type {ObycajnyPrijemData} from '../../types'
import {Table} from '../common/table/Table'

type ObycajnyPrijemProps = {
  dataId: string
  rows: ObycajnyPrijemData[]
  handleDataUpdate: (rows: ObycajnyPrijemData[]) => void
  title: string
  subtitle: string
  disabled: boolean
}

export const ObycajnyPrijem = ({dataId, rows, handleDataUpdate, subtitle, title, disabled}: ObycajnyPrijemProps) => {
  return (
    <Table
      dataId={dataId}
      title={title}
      subtitle={subtitle}
      rows={rows}
      handleDataUpdate={handleDataUpdate}
      disabled={disabled}
      sortBy="datumUhrady"
      columns={[
        {
          label: 'Číslo FA',
          property: 'cisloFA',
          inputType: 'text',
        },
        {
          label: 'Suma na faktúre',
          property: 'sumaNaFakture',
          inputType: 'number',
        },
        {
          label: 'Dátum vystavenia',
          property: 'datumSplatnosti',
          inputType: 'date',
        },
        {
          label: 'Dátum úhrady',
          property: 'datumUhrady',
          inputType: 'date',
        },
      ]}
    />
  )
}
