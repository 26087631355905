import {Stack, Typography} from '@mui/material'
import {Box} from '@mui/system'
import type {ReactNode} from 'react'
import React from 'react'

type AdminWrapProps = {
  title?: string
  children: ReactNode
  actions?: ReactNode
}

export const AdminWrap = ({title, children, actions}: AdminWrapProps) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      boxShadow="rgb(145 158 171 / 48%) 0px 0px 1px 0px, rgb(145 158 171 / 24%) 0px 2px 4px -1px"
      bgcolor="white"
      borderRadius={1}
    >
      <Stack spacing={1}>
        <Stack spacing={1} paddingBottom={actions !== undefined ? 1 : 2} paddingTop={2} paddingX={2}>
          {title !== undefined && title !== '' && (
            <Typography variant="body1" sx={{fontWeight: '500'}} component="h2">
              {title}
            </Typography>
          )}
          {children}
        </Stack>
        {actions !== undefined && (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            bgcolor="#fafafa"
            paddingY={1}
            paddingX={2}
            borderTop={1}
            borderColor="#ddd"
            sx={{borderRadius: '0 0 4px 4px'}}
          >
            {actions}
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
